import React from "react";
import sprentzo from '../HomeHeader/images/logo1.png';
import maplogo from '../HomeHeader/images/map.png';
import './index.css'

function HomePageHeader() {
    return (
        <div className="home-page-header-container">
            <div className="homepage-header">
                <img src={sprentzo} alt="sprentzoimg" className='home-logo-image' />
                <div className="header-location">
                    <div className="location-title">M G Road</div>
                    <img src={maplogo} alt="mapicon" className='home-map-image' />
                </div>
            </div>
            {/* <div className="Home-browser-button">
                <button type="click"
                    onClick={(e) => {

                        alert("message Sent");
                    }}
                >
                    Get a friend on-board, Get a month of supply</button>
            </div> */}
        </div>
    );
}

export default HomePageHeader;