import banner from "../../../assets/banner1.png";
import i1 from "./1.png";
import i2 from "./2.png";
import i3 from "./3.png";
import Slider from "react-slick";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const HomeMainBanner = ({ images = [] }) => {
  return (
    <div
      style={{
        width: "calc( 100% - 20px)",
        maxWidth: 500,

        margin: "auto",
      }}
    >
      <Slider {...settings}>
        {images.map((im, i) => (
          <div key={i}>
            <div>
              <img
                src={im}
                alt={"banner"}
                width={"100%"}
                style={{ minHeight: 200 }}
              />
            </div>
          </div>
        ))}
        {/* <div>
          <div>
            <img src={i2} alt={"banner"} width={"100%"} />
          </div>
        </div>
        <div>
          <div>
            <img src={i3} alt={"banner"} width={"100%"} />
          </div>
        </div> */}
      </Slider>
    </div>
  );
};
export default HomeMainBanner;
