import React from "react";
import './index.css';


function ProductDeliveryestimate() {
    return (
        <div className="product-delivery-estimate-container">
            <ul className="product-delivery-estimate-content">
                <li>Black and white</li>
                <li>7-days easy return available </li>
                <li>FREE shipping for orders above 2499</li>
            </ul>
        </div>
    )
}

export default ProductDeliveryestimate;