import React from "react";

import "./index.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";


let salesData = [
    {
        image: process.env.PUBLIC_URL + '/Assests/SalesPage/shoo.png',
        sales: process.env.PUBLIC_URL + '/Assests/SalesPage/sales.png',
        paragraph: "To enjoy your favorite sports, exercises, outdoor and indoor activities, you need to have the right kind of equipment. At Amazon India we offer you a range of sports and fitness equipment, indoor and outdoor accessories that you can shop online for. Explore our collection of sporting gear, gadgets and much more for all sportsincluding Cricket, Badminton, Lawn Tennis, Golf, Basketball, TableTennis, Volleyball and Football.",
        sales1: process.env.PUBLIC_URL + '/Assests/SalesPage/sales1.png',
        image1: process.env.PUBLIC_URL + '/Assests/SalesPage/weight.png',
    },

    {
        image: process.env.PUBLIC_URL + '/Assests/SalesPage/shoo.png',
        sales: process.env.PUBLIC_URL + '/Assests/SalesPage/sales.png',
        paragraph: "To enjoy your favorite sports, exercises, outdoor and indoor activities, you need to have the right kind of equipment. At Amazon India we offer you a range of sports and fitness equipment, indoor and outdoor accessories that you can shop online for. Explore our collection of sporting gear, gadgets and much more for all sportsincluding Cricket, Badminton, Lawn Tennis, Golf, Basketball, TableTennis, Volleyball and Football.",
        sales1: process.env.PUBLIC_URL + '/Assests/SalesPage/sales1.png',
        image1: process.env.PUBLIC_URL + '/Assests/SalesPage/weight.png',
    },

    {
        image: process.env.PUBLIC_URL + '/Assests/SalesPage/shoo.png',
        sales: process.env.PUBLIC_URL + '/Assests/SalesPage/sales.png',
        paragraph: "To enjoy your favorite sports, exercises, outdoor and indoor activities, you need to have the right kind of equipment. At Amazon India we offer you a range of sports and fitness equipment, indoor and outdoor accessories that you can shop online for. Explore our collection of sporting gear, gadgets and much more for all sportsincluding Cricket, Badminton, Lawn Tennis, Golf, Basketball, TableTennis, Volleyball and Football.",
        sales1: process.env.PUBLIC_URL + '/Assests/SalesPage/sales1.png',
        image1: process.env.PUBLIC_URL + '/Assests/SalesPage/weight.png',
    },

]

function SalesPage() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        prevArrow: false,
        nextArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Slider {...settings}>
                {salesData.map((d, i) => (

                    <div className="sales-page-container">

                        <div className="sales-page-offer-card-container">

                            <div className="sales-page-offer-card-content">
                                <img src={d.sales} alt="" />
                            </div>
                            <div className="sales-page-offer-card-image">
                                <img src={d.image} alt="" />
                            </div>

                        </div>

                        <div className="sales-page-container-paragraph">{d.paragraph}  </div>

                        <div className="sales-page-offer-card-container">

                            <div className="sales-page-offer-card-content">
                                <img src={d.sales1} alt="" />
                                
                                <button className="sales-page-button">View Product</button>

                            </div>
                           
                            <div className="sales-page-offer-card-image">
                                <img src={d.image1} alt="" style={{ margin: 26 }} />
                            </div>
                            
                        </div>
                    </div>
                ))}
            </Slider>
        </>

    )
}

export default SalesPage