import sprentzo from "./logo1.png";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { login } from "../../apiIntegrations/auth";
import "./index.css";
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          background: "#fff",
          flexDirection: "column",
          maxWidth: 500,
        }}
      >
        <dv
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={sprentzo} alt="sprentzoimg" height={90} />
        </dv>
        <div
          style={{ marginLeft: 20, fontWeight: 600, fontSize: 20 }}
          onClick={(e) => navigate("/signup")}
        >
          <svg
            fill="#000000"
            version="1.1"
            id="Capa_1"
            width="30px"
            height="30px"
            viewBox="0 0 400.004 400.004"
          >
            <g>
              <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757   c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072   c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315   C400.004,190.438,392.251,182.686,382.688,182.686z" />
            </g>
          </svg>
          <span style={{ position: "relative", top: -8, marginLeft: 10 }}>
            Back
          </span>
        </div>

        <div
          style={{
            flex: 1,
            overflow: "scroll",
            overflowX: "hidden",
            padding: 20,
            textAlign: "justify",
          }}
        >
          <h2>Privacy Policy:</h2>
          <p>
            At Sprentzo Sports Pvt. Ltd, we are committed to protecting the
            privacy and personal information of our customers. This Privacy
            Policy outlines how we collect, use, disclose, and safeguard your
            information when you use our website and services. By accessing or
            using our platform, you agree to the terms of this Privacy Policy.
          </p>
          <b>Information We Collect</b>
          <p>
            <b>1.1 Personal Information:</b> We may collect personal information
            such as your name, email address, phone number, and billing address
            when you create an account, make a reservation, or communicate with
            us. This information is necessary to process your rentals, provide
            customer support, and improve our services.
          </p>
          <p>
            <b>1.2 Usage Information:</b> We automatically collect certain
            information when you visit our website or use our services,
            including your IP address, device information, browser type, and
            usage patterns. This information helps us analyze trends, administer
            our platform, and improve the user experience.
          </p>
          <h3>How We Use Your Information</h3>
          <p>
            <b>2.1 Rental Services:</b> We use your personal information to
            facilitate the rental process, including processing reservations,
            providing rental equipment, and managing payments.
          </p>
          <p>
            <b>2.2 Customer Support:</b> We may use your information to respond
            to your inquiries, provide assistance, and address any issues or
            concerns you may have.
          </p>
          <p>
            <b>2.3 Communication:</b> We may send you important notifications,
            updates, or promotional offers related to our services. You have the
            option to unsubscribe from marketing communications at any time.
          </p>
          <p>
            <b>2.4 Analytics and Improvements:</b> We may use your information
            for statistical analysis, market research, and to improve our
            platform, products, and services.
          </p>
          <h3>Information Disclosure</h3>
          <p>
            <b>3.1 Third-Party Service Providers:</b> We may share your
            information with trusted third-party service providers who assist us
            in operating our business and delivering our services, such as
            payment processors and logistics partners. These service providers
            are required to maintain the confidentiality and security of your
            information.
          </p>
          <p>
            <b>3.2 Legal Compliance:</b> We may disclose your information to
            comply with applicable laws, regulations, legal processes, or
            government requests. We may also disclose your information to
            protect our rights, property, and safety, or the rights, property,
            and safety of our users or others.
          </p>
          <h3>Data Security</h3>
          We implement security measures to protect your personal information
          from unauthorized access, use, disclosure, or alteration. However,
          please be aware that no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
          <h3>Your Choices</h3>
          You have the right to access, update, correct, or delete your personal
          information. You can manage your account settings and preferences
          through our platform or by contacting us. Please note that deleting
          certain information may limit your ability to use our services.
          <h3>Changes to this Privacy Policy</h3>
          We may update this Privacy Policy from time to time. Any changes will
          be effective upon posting the revised policy on our website. We
          encourage you to review this Privacy Policy periodically for any
          updates or changes.
          <h3>Contact Us</h3>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at suport@sprentzo.com.
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
