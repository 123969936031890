import { useEffect } from "react";
import Slider from "react-slick";
import badmintonData from "../../../data/badminton.json";
import cricket from "../../../data/cricket.json";
import football from "../../../data/football.json";
import { useNavigate } from "react-router-dom";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
};

const HomePageProductList = ({ title, products = [] }) => {
  // useEffect(() => {
  //   fetch(
  //     "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/107604/01/sv01/fnd/IND/fmt/png/Deuce-Unisex-Indoor-Sport-Shoes"
  //   )
  //     // .then((response) => response.json())
  //     .then((data) => console.log(data))
  //     .catch((error) => console.error(error));
  // }, []);
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: 20, marginBottom: 50 }}>
      <div
        style={{
          //   border: "1px solid red",
          paddingLeft: 20,
          paddingBottom: 20,
          letterSpacing: 0.7,
          color: "#444",
          fontSize: 20,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {"Start With Assured Quality"}
      </div>
      <div
        style={{
          width: "calc( 100% - 20px)",
          margin: "auto",
        }}
      >
        <Slider {...settings}>
          {products.map((d, i) => {
            return (
              <div key={i} onClick={(e) => navigate("/discription/" + d._id)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: 350,
                        // height: 180,
                        // border: "1px solid red",
                        background: "#F5F8F9",
                        // borderRadius: 20,
                        // boxShadow: "rgb(146 145 145 / 29%) 1px 1px 20px 0px",
                        margin: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: 6,
                      }}
                    >
                      <div
                        style={{
                          marginTop: 50,
                          width: 300,
                          // background: "#eee",
                          // border: "1px solid #ccc",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={d.images[0]}
                          alt={d.images[0]}
                          // width={"65%"}
                          style={{ margin: 10, height: 200 }}
                        />
                        {/* <div style={{ textAlign: "center" }}>{d.name}</div> */}
                      </div>
                      <div style={{ width: 220, textAlign: "center" }}>
                        <div
                          style={{
                            fontSize: 12,
                            // textAlign: "left",
                            letterSpacing: ".6px",
                            color: "#555",
                            fontWeight: "600",
                            marginBottom: 5,
                          }}
                        >
                          {d.name}
                        </div>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                          <div>
                            <div style={{ padding: 10, fontSize: 12, color: "#666" }}>Buy</div>
                            <div
                              style={{ padding: 10, paddingTop: 0, fontSize: 14, color: "#333" }}
                            >
                              <strike style={{ color: "red" }}>
                                <span style={{ color: "#888", fontWeight: 600 }}>
                                  ₹{d.buy}
                                </span>
                              </strike>
                            </div>
                          </div>
                          <div>
                            <div style={{ padding: 10, fontSize: 12, color: "#333" }}>Rent</div>
                            <div
                              style={{
                                padding: 10,
                                paddingTop: 0,
                                fontSize: 14,
                                color: "#333",
                                fontWeight: 600,
                              }}
                            >
                              ₹{d.rent}
                            </div>
                          </div>
                        </div>
                        {/* <div
                          style={{
                            fontSize: 10,
                            letterSpacing: ".6px",
                            color: "#666",
                            fontWeight: "400",
                            marginBottom: 5,
                          }}
                        >
                          Available on rent
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default HomePageProductList;
