import React from "react";
import "./index.css";
import { useState } from "react";
import image from "../DiscriptionDropdown/img.png";
import img from "../DiscriptionDropdown/img2.png";

function CustomDropdown() {
  const [liked, setLiked] = useState(false);

  const [selectedValue, setSelectedValue] = useState("Black and white");
  const [showOptions, setShowOptions] = useState(false);
  const options = ["Black and white", "Multi colour"];

  function handleSelect(option) {
    setSelectedValue(option);
    setShowOptions(false);
  }

  function toggleOptions() {
    setShowOptions(!showOptions);
  }

  return (
    <div className="discription-item-color-container">
      <div className="custom-dropdown">
        <label>Colour</label>

        <div className="selected-value" onClick={toggleOptions}>
          {selectedValue ? selectedValue : "Related Product"}
          {liked && (
            <img src={img} alt="like" onClick={(e) => setLiked(false)} />
          )}
          {!liked && <img src={image} alt="" onClick={(e) => setLiked(true)} />}
        </div>
        {showOptions && (
          <ul className="options">
            {options.map((option) => (
              <li key={option} onClick={() => handleSelect(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CustomDropdown;
