import ball from "./ball.png";
import bat from "./bat.png";
import boot from "./boot.png";
import { useNavigate } from "react-router-dom";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
};

const HomeTrendingNow = ({ title, items = [] }) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 50 }}>
      <div
        style={{
          //   border: "1px solid red",
          paddingLeft: 20,
          paddingBottom: 15,
          letterSpacing: 0.7,
          color: "#333",
          fontSize: 18,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {"Trending Now"}
      </div>
      <div
        style={{
          width: "calc( 100% - 20px)",
          margin: "auto",
        }}
      >
        <TrendingCard
          title={"Badminton "}
          details={" Only the cleanest shoes and the lightest racquets"}
          bgColor="#f05025"
          image={boot}
          height="70"
          sports={"Badminton"}
        />
        <TrendingCard
          title={"High Quality Puma Footballs"}
          details={"Make every game, the best"}
          // bgColor="rgb(68 69 185)"
          bgColor="#fdd93b"
          color="rgb(120 104 171)"
          height="100"
          image={ball}
          sports={"Football"}
        />
        <TrendingCard
          title={"Box Cricket Bats"}
          details={"Explore the range, light weight to heavy hitters"}
          bgColor="rgb(120 104 171)"
          image={bat}
          sports={"Cricket"}
        />
      </div>
    </div>
  );
};

export default HomeTrendingNow;

const TrendingCard = ({
  title,
  details,
  image,
  bgColor = "#fff",
  height = 100,
  sports,
  color = "#fff",
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        localStorage.setItem("filter", sports);
        navigate("/search-results/");
      }}
      style={{
        height: 100,
        // border:"1px solid red",
        overflow: "hidden",
        background: bgColor,
        margin: 5,
        display: "flex",
        color,
      }}
    >
      <div
        style={{
          padding: 20,
          flex: 1,

          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <div style={{ fontSize: 14, fontWeight: 700 }}>{title}</div>
          <div style={{ fontSize: 12, fontWeight: 500 }}>{details}</div>
        </div>
      </div>
      <div
        style={{
          width: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <img src={image} height={height} />
      </div>
    </div>
  );
};
