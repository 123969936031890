import React, { useState } from "react";

import "./index.css";

import star from "./star.png";

import stars from "./stars.png";

let starsData = [
  {
    star: star,
    stars: stars,
  },
  {
    star: star,
    stars: stars,
  },
  {
    star: star,
    stars: stars,
  },
  {
    star: star,
    stars: stars,
  },
  {
    star: star,
    stars: stars,
  },
];

function ProductDescriptionContent({ id }) {
  const [liked, setLiked] = useState(false);

  return (
    <div className="product-description-content-block">
      <div className="product-description-content">{id}</div>
      <div className="product-description-image-container">
        {starsData.map((d, i) => (
          <div className="product-description-star-images" key={i}>
            {liked && (
              <img src={d.stars} alt="like" onClick={(e) => setLiked(false)} />
            )}
            {!liked && (
              <img src={d.star} alt="" onClick={(e) => setLiked(true)} />
            )}
          </div>
        ))}

        <div className="product-description-image-content">
          (15 customer review)
        </div>
      </div>
      <div className="product-description-rent-container">
        <div className="product-description-buy">Buy </div>
        <div className="product-description-buy-amount">₹800</div>
        <div className="product-description-rent">Rent ₹120</div>
      </div>
    </div>
  );
}

export default ProductDescriptionContent;
