import React from "react";
import "./index.css";
import logout from "../UserQuery/images/logout.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clearCookiesForAnotherDomain from '../../../utils/clearCookies'
let ProfileSettingData = [
  {
    icon: process.env.PUBLIC_URL + "/Assests/userquery/walkm.png",
    title: "Setting",
    image: process.env.PUBLIC_URL + "/Assests/userquery/next.png",
  },
  // {
  //   icon: process.env.PUBLIC_URL + "/Assests/userquery/card.png",
  //   title: "Previous invoice ",
  //   image: process.env.PUBLIC_URL + "/Assests/userquery/next.png",
  // },
  // {
  //   icon: process.env.PUBLIC_URL + "/Assests/userquery/star.png",
  //   title: "Reward system",
  //   image: process.env.PUBLIC_URL + "/Assests/userquery/next.png",
  // },
  // {
  //   icon: process.env.PUBLIC_URL + "/Assests/userquery/cust.png",
  //   title: "Support",
  // },
  // {
  //   icon: process.env.PUBLIC_URL + "/Assests/userquery/faq.png",
  //   title: "FAQ",
  // },
  // {
  //   icon: process.env.PUBLIC_URL + "/Assests/userquery/call.png",
  //   title: "Contact Us",
  // },
];

function UserSetting() {
  const navigate = useNavigate();

  const [isLoggedIN, setIsLoggedIN] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    console.log(token);
    if (token) {
      setIsLoggedIN(true);
    } else setIsLoggedIN(false);
  }, []);

  return (
    <>
      {/* <div
        style={{
          margin: 15,
          background: "#f1f1f1",
          borderRadius: 5,
          paddingBottom: 10,
        }}
      >
        {ProfileSettingData.map((d, i) => (
          <div
            className="profile-setting-block1"
            style={{ padding: 5 }}
            key={i}
          >
            <div className="profile-setting-icon">
              <img src={d.icon} alt="" />
            </div>
            <div className="profile-setting-address">{d.title}</div>
            <div className="profile-setting-image">
              <img src={d.image} alt="" />
            </div>
          </div>
        ))}
      </div> */}
      <div
        // className="profile-setting-logout"
        style={{
          margin: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="profile-logout-image">
          <img src={logout} alt="" />
        </div>
        <div
          className="profile-logout-text"
          onClick={async () => {
            // localStorage.clear();
            localStorage.removeItem('Authorization')
            localStorage.removeItem('userProfile')
            localStorage.removeItem('phoneNumberForgotPassword')
            localStorage.removeItem('otpForgotPassword')
            localStorage.removeItem('filter')
            clearCookiesForAnotherDomain('https://otpless.com')
            // const targetElement = document.getElementById('otpless-floating-button');
            // targetElement.style.display = 'hidden';
            setIsLoggedIN(false);
            navigate("/home");
          }}
        >
          Log out
        </div>
      </div>
    </>
  );
}

export default UserSetting;
