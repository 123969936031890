import "./index.css";
import logoImage from "./image/Sprentzo.png";
import googleIcon from "./image/google.png";
import facebookIcon from "./image/fb.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, whatsappLogin } from "../../apiIntegrations/auth";
import Modal from '../../components/Modal'
import modalContent from '../../modalContent'
const Login = () => {
  const { loginFailed } = modalContent
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(false)
  }
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    console.log(token);
    if (token) {
      navigate("/home");
    }
  }, []);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://otpless.com/auth.js';
    script.id = 'otplessIdScript';
    document.body.appendChild(script);


    (window).otpless = async (otplessUser) => {
      let res = await whatsappLogin({ ...otplessUser, otplessToken: otplessUser.token, firstName: otplessUser.name || otplessUser.waName || 'N/A' });
      console.log(res.token);
      if (res.error) {
        setOpen(true)
        console.log(res);
      } else {
        localStorage.setItem("Authorization", res.token);
        navigate("/home");
      }
    }
    return () => {
      const divid = document.getElementById("otplessIdScript")
      document.body.removeChild(divid)
    }
  }, [])
  // useEffect(() => {
  //   window.otpless = async (otplessUser) => {
  //     let res = await whatsappLogin({ ...otplessUser, otplessToken: otplessUser.token, firstName: otplessUser.name || otplessUser.waName || 'N/A' });
  //     console.log(res.token);
  //     if (res.error) {
  //       setOpen(true)
  //       console.log(res);
  //     } else {
  //       localStorage.setItem("Authorization", res.token);
  //       navigate("/home");
  //     }
  //   };
  // }, []);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // alert(`Logged in with phone number: ${phone}`);
    setPhone("");
    setPassword("");
  };

  const handleForgotPassword = () => {
    alert("Forgot Password clicked");
  };

  // const handleGetMeBack = () => {
  //     alert('Get Me Back clicked');
  // };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            maxWidth: 500,
            height: "100%",
            background: "#fff",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 40,
                // marginTop: 80,
              }}
            >
              <img src={logoImage} alt="Logo" height="40" m />
              <div
                style={{
                  color: "#b0acc2",
                  paddingTop: 20,
                  textAlign: "center",
                }}
              >
                <div>Looks like you stepped out of the pitch...</div>
                <div>No worries, get back now!</div>
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>
              <span style={{ color: "#444" }}>+91</span>{" "}
              <input
                type="tel"
                id="phoneNumber"
                value={phone}
                onChange={handlePhoneNumberChange}
                required
                className="log-input-user-id"
                placeholder="Phone Number"
              />
            </span>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="log-input"
              placeholder="Password"
            />
            <div className="login-signup">
              <span
                className="login-forgot-password"
                onClick={(e) => {
                  navigate("/send-otp");
                }}
              >
                Forgot Password
              </span>
            </div>
            <button
              className="login-get-me-back-button"
              style={{ color: "#fff", fontWeight: 500, letterSpacing: 1 }}
              onClick={async () => {
                let res = await login({ userId: phone, password });
                console.log(res.token);
                if (res.error) {
                  setOpen(true)
                  console.log(res);
                } else {
                  localStorage.setItem("Authorization", res.token);
                  navigate("/home");
                }
              }}
            >
              Login
            </button>
            <div className="login-signup">
              <span
                style={{
                  fontSize: 18,
                  paddingTop: 14,
                  color: "#bbbdcd",
                }}
              >
                I am yet to
              </span>
              <span
                className="login-signup-button"
                onClick={(e) => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </span>
            </div>
            {/* <div id="otpless-login-page"></div> */}
            <div style={{ display: 'flex', alignItems: 'center', width: '80%', margin: '20px 0', marginBottom: '0px' }}>
              <div style={{ flex: '1', borderBottom: '1px solid #7566a3' }}></div>
              <div style={{ margin: '0 10px', color: '#7566a3', fontWeight: 'bold' }}>OR</div>
              <div style={{ flex: '1', borderBottom: '1px solid #7566a3' }}></div>
            </div>
            <div id="otpless" custom={true}></div>


            {/* <div>
            <div className="login-or-line">
              <hr className="login-line" />
              <span
                className="login-or-text"
                style={{ fontSize: 12, color: "#ccc" }}
              >
                OR
              </span>
              <hr className="login-line" />
            </div>
            <div className="login-social-links">
              <a target="_blank" style={{}}>
                <img
                  width={50}
                  height={50}
                  src={googleIcon}
                  alt="Google"
                  // className="login-social-icon"
                  style={{ margin: 5 }}
                />
              </a>
              <a style={{}} target="_blank">
                <img
                  width={50}
                  height={50}
                  src={facebookIcon}
                  alt="Facebook"
                  // className="login-social-icon"
                  style={{ margin: 5 }}
                />
              </a>
            </div>
          </div> */}
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
      <Modal open={open} message={loginFailed.message} icon={loginFailed.icon}
        description={loginFailed.description}
        backgroundColor={loginFailed.backgroundColor}
        color={loginFailed.color} handleOpen={handleOpen} />
    </>
  );
};

export default Login;

// let bk = () => (
//   <div className="login-container">
//     <img src={logoImage} alt="Logo" className="login-logo" />
//     <p className="login-subtitle">
//       Looks like you stepped out of the pitch...
//       <br />
//       No worries, get back now!
//     </p>
//     <form onSubmit={handleLogin} className="login-form">
//       <div className="login-account-form-group">
//         <label htmlFor="phoneNumber" className="login-label"></label>
//         <input
//           type="tel"
//           id="phoneNumber"
//           value={phone}
//           onChange={handlePhoneNumberChange}
//           required
//           className="log-input"
//           placeholder="Phone Number"
//         />
//       </div>
//       <div className="login-account-form-group">
//         <label htmlFor="password" className="login-label"></label>
//         <input
//           type="password"
//           id="password"
//           value={password}
//           onChange={handlePasswordChange}
//           required
//           className="log-input"
//           placeholder="Password"
//         />
//       </div>
//       <div>
//         <a
//           href=""
//           type="button"
//           className="login-forgot-password"
//           onClick={handleForgotPassword}
//         >
//           Forgot Password
//         </a>
//       </div>
//       <button
//         className="login-get-me-back-button"
//         onClick={async () => {
//           let res = await login({ userId: phone, password });
//           console.log(res.token);
//           if (res.error) {
//             alert("Login Failed Please check the Credentials");
//             console.log(res);
//           } else {
//             localStorage.setItem("Authorization", res.token);
//             navigate("/home");
//           }
//         }}
//       >
//         Get Me Back
//       </button>
//     </form>
//     <div className="login-signup">
//       <h2 className="login-yet-sign">I am yet to</h2>
//       <span
//         className="login-signup-button"
//         onClick={(e) => {
//           navigate("/signup");
//         }}
//       >
//         Sign Up
//       </span>
//     </div>
//     <div>
//       <div className="login-or-line">
//         <hr className="login-line" />
//         <span className="login-or-text">OR</span>
//         <hr className="login-line" />
//       </div>
//       <div className="login-social-links">
//         <a
//           href="https://www.google.com"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="social-link"
//         >
//           <img src={googleIcon} alt="Google" className="login-social-icon" />
//         </a>
//         <a
//           href="https://www.facebook.com"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="login-social-link"
//         >
//           <img
//             src={facebookIcon}
//             alt="Facebook"
//             className="login-social-icon"
//           />
//         </a>
//       </div>
//     </div>
//   </div>
// );
