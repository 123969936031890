// Login signup api calls
import { get, post, put, del, patch } from "./fetcher";
const headers = {
  "Content-Type": "application/json",
};

export async function signup(payload) {
  try {
    const response = await post("signup", payload, headers);
    console.log(response);
    return response
  } catch (error) {
    await post("error-log", { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, headers)
    console.log(error);
    //return { error: true, message: error }
    return { error: true, message: error, errordata: error?.response?.data?.error || 'Unable to process your request, please contact admin' }
  }
}

export async function login(payload) {
  try {
    const response = await post("login", payload, headers);
    return response;
  } catch (error) {
    await post("error-log", { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, headers)
    return { error };
  }
}

export async function whatsappLogin(payload) {
  try {
    const response = await post("whatsapp-login", payload, headers);
    return response;
  } catch (error) {
    await post("error-log", { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, headers)
    return { error };
  }
}

export async function logout() {
  const token = localStorage.getItem("Authorization");
  try {
    const response = await post("logout", {}, { ...headers, Cookie: token });
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

export async function sendOTP(payload) {
  try {
    const response = await post("send-otp", payload, headers);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function verifyOTP(payload) {
  try {
    const response = await post("verify-otp", payload, headers);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function resetPassword(payload) {
  try {
    const response = await post("/forgot", payload, headers);
    return response;
  } catch (error) {
    return { error };
  }
}

export async function sports() {
  alert("hello")
  // const token = localStorage.getItem("Authorization");
  // try {
  //   const response = await post("logout", {}, { ...headers, Cookie: token });
  //   console.log(response);
  // } catch (error) {
  //   console.error(error);
  // }
}

