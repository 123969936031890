import "./index.css";
import arrowLeft from "./image/arrow-left.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "../../../apiIntegrations/auth";
import manPointingRight from './image/man-pointing-right.png';
const SendOTP = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState(localStorage.getItem("phoneNumberForgotPassword") || "");
  const [error, setError] = useState("");
  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    if (new RegExp(/[^\d]/g).exec(e.target.value)) {
      setError("Please enter valid phone number")
      return;
    }
    setError("")
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    let res = await sendOTP({ userId: phone });
    // console.log(res.token);
    // if (res.error) {
    //   alert("Login Failed Please check the Credentials");
    //   console.log(res);
    // } else {
    //   localStorage.setItem("Authorization", res.token);
    //   navigate("/home");
    // }
    console.log(phone.length !== 10, [6, 7, 8, 9].includes(phone[0]))
    if (phone.length !== 10 || [6, 7, 8, 9].includes(phone[0])) {
      setError("Please enter valid 10-digit phone number")
      return;
    }
    setError("")
    localStorage.setItem("phoneNumberForgotPassword", phone)
    // alert(`Logged in with phone number: ${phone}`);
    setPhone("");
    navigate("/verify-otp");
  };

  const handleForgotPassword = () => {
    alert("Forgot Password clicked");
  };

  return (
    <div className="page">
      <div className="sub-page">
        <div className="sub-page-header">
          <img src={arrowLeft} alt="Logo" height="24" width="24" onClick={() => navigate("/login")} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100vw",
            textAlign: "center"
          }}
        >
          <img src={manPointingRight} height="200" width="200" alt="man pointing right visual element" />
          <h1 style={{ margin: "0" }}><i>Forgot Password</i></h1>
          <p style={{ color: "#808080", margin: "12px 0px" }}>It's okay, we all take no-ball wickets once in a while</p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50
          }}
        >
          <span>
            <span style={{ color: "#444" }}>+91</span>{" "}
            <input
              type="tel"
              id="phoneNumber"
              value={phone}
              onChange={handlePhoneNumberChange}
              required
              className="log-input-user-id"
              placeholder="Phone Number"
            />
            <p className="error">{error}</p>
          </span>

          <button
            className="send-otp-button"
            style={{ color: "#fff", fontWeight: 500, letterSpacing: 1 }}
            onClick={handleSendOTP}
          >
            Send OTP
          </button>

        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  );
};

export default SendOTP;
