import "./index.css"
function Modal({ open, message, icon, description, backgroundColor = "pink", color = "green", handleOpen }) {
    return (
        <div className="modal-container" style={{
            display: open ? "flex" : "none"
        }} onClick={() => { handleOpen(false) }}>
            <div className="modal" style={{
                backgroundColor
            }}>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: "center"
                }}>
                    <h1 className="modal-title" style={{
                        color
                    }}>{message}</h1>
                    <img src={icon} height="40" width="40" alt={`icon for modal related to ${message}`} />
                </div>
                <p className="modal-description" style={{
                    color
                }}>{description}</p>
            </div>
        </div >
    );
}

export default Modal;