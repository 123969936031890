import React from "react";
import './index.css';


function CartPayment({cartItems}) {
    const price= cartItems.map(d=>d.price*d.quantity).reduce((a,b)=>a+b,0)
    const delevaryCharges = cartItems.map(d=>d.delevaryCharges || 1 ).reduce((a,b)=>a+b,0)
    return (
            <div className="cart-payment-title-container">

                <div className="cart-payment-title">Payment summary</div>

                <div className="cart-payment-title-block">
                    <div className="cart-payment-items">
                        <div className="cart-payment-item">{cartItems.length} items</div>
                        <div className="cart-payment-price">₹{price}</div>
                    </div>
                    <div className="cart-payment-items">
                        <div className="cart-payment-item">Delivery Charge</div>
                        <div className="cart-payment-price">{delevaryCharges}</div>
                    </div>
                    <div className="cart-payment-total">
                        <div className="cart-payment-item">Total</div>
                        <div className="cart-payment-price">₹{price + delevaryCharges}</div>
                    </div>
                    <div className="cart-payment-button">
                        <button type="checkout"
                            onClick={(e) => {

                                alert("items checkout");
                            }}
                        >
                            Checkout</button>
                    </div>
                </div>

            </div>
    )
}

export default CartPayment;