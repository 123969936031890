import React from "react";
import "./index.css";
import home from "../Footer/images/home.png";
import search from "../Footer/images/search.png";
import star from "../Footer/images/star.png";
import cart from "../Footer/images/cart.png";
import profile from "../Footer/images/profile.png";

import { Link, useLocation } from "react-router-dom";

function FooterPage() {
  const location = useLocation();
  return (
    <div style={{ height: 80, border: "1px solid red" }}>footer</div>
    // <div className="footer-container">
    //     <span className="f-logo">
    //         <Link to="/home" className={location.pathname === '/home' ? 'tab_active' : ''}>
    //             <img src={home} alt="sign-facebook" height="30" width="30" className="footer-logo" />
    //         </Link>
    //     </span>
    //     <span className="f-logo">
    //         <Link to="/search" className={location.pathname === '/search' ? 'tab_active' : ''}>
    //             <img src={search} alt="sign-facebook" height="30" width="30" className="footer-logo" />
    //         </Link>
    //     </span>
    //     <span className="f-logo">
    //     <Link to="/wishlist" className={location.pathname === '/wishlist' ? 'tab_active' : ''}>
    //         <img src={star} alt="sign-facebook" height="30" width="30" className="footer-logo" />
    //         </Link>
    //     </span>
    //     <span className="f-logo">
    //         <Link to="/cart" className={location.pathname === '/cart' ? 'tab_active' : ''}>
    //             <img src={cart} alt="sign-facebook" height="30" width="30" className="footer-logo" />
    //         </Link>
    //     </span>
    //     <span className="f-logo">
    //     <Link to="/profile" className={location.pathname === '/profile' ? 'tab_active' : ''}>
    //         <img src={profile} alt="sign-facebook" height="30" width="30" className="footer-logo" />
    //         </Link>
    //     </span>

    // </div>
  );
}

export default FooterPage;
