import React from "react";
import './index.css';


function OrderConfirmationPage() {
    return(
        <div className="orderpage">
            <div className="blurrimage">
               <div className="order-container">
                    <div className="image-container"></div>
                    <div className="title-container">
                        <h1 className="title">Thank you!</h1>
                    </div>
                    <div className="subtitle-container">
                        <span className="subtitle">Order number:</span>
                        
                        <span className="integer">  0137356252829</span>
                    </div>
                    <div className="subtitle-container-1">
                        <p className="subtitle-1">Your order is placed successfully. please
                            go to order page to manage status.</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}
export default OrderConfirmationPage;