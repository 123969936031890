import React from "react";
import "./index.css";

function RelatedGymProduct({ NewAllGymProductData }) {
  return (
    <div className="related-gym-product-container">
      {NewAllGymProductData.map((d, i) => (
        <div className="related-gym-product-block">
          <div className="related-product-container-block">
            <div className="related-product-title-section">{d.title}</div>
            <div className="related-product-discription-section">
              {d.subtitle}
            </div>
            <div className="related-product-price-section">
              <div className="related-product-price1">
                <div className="related-product-buy">{d.buy} </div>
                <div className="related-product-buy-amount">{d.amount}</div>
              </div>
              <div className="related-product-price2">{d.rent}</div>
            </div>
          </div>
          <div className="related-product-image-section">
            <div className="related-product-image">
              <img src={d.image} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RelatedGymProduct;
