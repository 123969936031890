import Slider from "react-slick";
import cricket from "./images/cricketBat.png";
import football from "./images/football.png";
import badminton from "./images/badminton.png";
import { useNavigate } from "react-router-dom";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const CategoriesList = ({ sportsSelected, onChange = () => { } }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: 40 }}>
      <div
        style={{
          //   border: "1px solid red",
          //   padding: 10,
          width: "calc( 100% - 20px)",
          //   maxWidth: 400,
          margin: "auto",
        }}
      >
        <Slider {...settings}>
          {[
            {
              title: "Football",
              icon: football,
            },
            {
              title: "Cricket",
              icon: cricket,
            },
            {
              title: "Badminton",
              icon: badminton,
            },
          ].map((d, i) => {
            return (
              <div key={i}>
                <div
                  onClick={(e) => {
                    navigate("/search-results");
                    if (sportsSelected === d.title) onChange(null);
                    else onChange(d.title);
                    localStorage.setItem("filter", d.title);
                    console.log(localStorage);
                  }}
                  style={{
                    // border: "1px solid red",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 150,
                      height: 150,

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: 180,
                        height: 180,
                        // borderRadius: 20,
                        // boxShadow: "0px 3px 7px 1px #92919166",
                        // margin: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={d.icon} alt={d.title} width={"65%"} />
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        letterSpacing: ".6px",
                        color: sportsSelected === d.title ? "#000" : "#666",
                        fontWeight: sportsSelected === d.title ? "700" : "400",
                      }}
                    >
                      {d.title}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default CategoriesList;
