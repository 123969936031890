import React, { useState } from "react";
import Images from "./Images.js";
import "./index.css";
import returnicon from '../ProductImageGallery/images/backicon.png';
import { Link } from "react-router-dom";

function ProductImageGallery() {
    const [selectedImg, setSelectedImg] = useState(Images[0]);

    return (
        <>
            <div className="discription-header-container">
                <div className="discription-header-icon">
                    <Link to='/home'>
                        <img src={returnicon} alt='' />
                    </Link>
                </div>
                <div className="discription-header-title">Discription</div>
            </div>
            <div className="product-image-gallery">
                <div className="product-image-gallery-container">
                    <img src={selectedImg} alt="Selected" className="selected" />
                    <div className="imgContainer">
                        {Images.map((img, index) => (
                            <img
                                style={{ border: selectedImg === img ? "" : "" }}
                                key={index}
                                src={img}
                                alt=""
                                onClick={() => setSelectedImg(img)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductImageGallery;
