import MianFooter from "../../components/v2/MianFooter";
import MainHeader from "../../components/v2/MainHeader";
import React, { useEffect, useState } from "react";
import { get, post } from "../../apiIntegrations/fetcher";
import { useParams } from "react-router-dom";
import imagePopup from "./Order-Placed.png";
function Order() {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  let interval = null;
  useEffect(() => {
    loadOrders(id);
    interval = setInterval(() => {
      loadOrders(id);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  async function loadOrders(id) {
    console.log(id);
    if (!id) return;
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    console.log(userProfile);
    const res = await get(`orders/${id}`);
    setOrders(res);
  }

  return (
    <div
      style={{
        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: 20,
            color: "#7868ab",
            fontWeight: "600",
          }}
        >
          Order Details
        </div>
        <div style={{ fontSize: 36, textAlign: "center" }}>
          {/* <span style={{ fontSize: 10, color: "#888" }}>Status:</span> */}
          <b
            style={{
              color: colors(orders.razorPayment?.event.split(".")[1]),
              textTransform: "capitalize",
            }}
          >
            {orders.razorPayment &&
              orders.razorPayment?.event.split(".")[1] !== undefined
              ? status(orders.razorPayment?.event.split(".")[1])
              : "Pending"}
          </b>
        </div>
        <div style={{ padding: 10 }}>
          <h2>Items</h2>
          {orders?.items?.map((item, i) => {
            return (
              <div
                key={item.product._id}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    width: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #ccc",
                  }}
                >
                  <img
                    src={item.product.images[0]}
                    height="100"
                    width="100"
                    style={{ margin: 10, objectFit: 'contain' }}
                    alt={item.product.images[0]}
                  />
                </div>
                <div>
                  <div style={{ fontSize: 16, padding: 5, fontWeight: 600 }}>
                    {" "}
                    {item.product.name}
                  </div>
                  <div style={{ fontSize: 14, padding: 5 }}>
                    Quantity: {item.quantity}
                  </div>
                  <div style={{ fontSize: 14, padding: 5 }}>
                    Price: ₹ {item.product.type === 'Event' ? item.product.amount : item.product.rent}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <MianFooter />
      {showPopup && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            maxWidth: 500,
            background: "#cccccccc",
            zIndex: 999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div
              style={{
                textAlign: "right",
                padding: 5,
                color: "#000",
                fontSize: 20,
              }}
              onClick={(e) => setShowPopup(false)}
            >
              close
            </div>
            <img src={imagePopup} width={375} style={{ maxWidth: 400 }} />
          </div>
        </div>
      )}
    </div>
  );
}
function status(status) {
  switch (status) {
    case "authorized":
      return "Paid";
    case "failed":
      return "Failed";
    default:
      return "Pending";
  }
}
function colors(status) {
  switch (status) {
    case "authorized":
      return "green";
    case "failed":
      return "red";
    default:
      return "#888";
  }
}
export default Order;
