import { get ,post, patch} from "./fetcher";
export async function addToWishlist(id){
    let profile = JSON.parse(localStorage.getItem("userProfile"));
    let res = await get("wishlists?user=" + profile._id);
    console.log(res);
    if (res.count === 0) {
      const cRes = await post("wishlists", { products: [], user: profile._id });
      console.log(cRes);
    }
    res = await get("wishlists?user=" + profile._id);

    const addRes  = await patch("wishlists/"+res.data[0]._id,{products:[id,...res.data[0].products.map(d=>d._id)]})
    console.log(addRes)
} 