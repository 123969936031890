import React from "react";
import './index.css';


let NewRentProductData = [
    {
        image: process.env.PUBLIC_URL + '/Assests/RelatedAllProduct/tytax.png',
        title: 'TYTAX Machine',
        subtitle : 'Sports equipment Sports equipment Sports equipm Sports equipment',
        buy: 'Buy',
        amount : '28000',
        rent: 'Rent ₹1200',
    },
    {
        image: process.env.PUBLIC_URL + '/Assests/RelatedAllProduct/tytax.png',
        title: 'TYTAX Machine',
        subtitle : 'Sports equipment Sports equipment Sports equipm Sports equipment',
        buy: 'Buy',
        amount : '28000',
        rent: 'Rent ₹1200',
    },
    
]
function RelatedRentProduct() {
    return (
        <div className="related-rent-product-container">
            {NewRentProductData.map((d, i) => (
                <div className="related-rent-product-block">
                    <div className="related-rent-product-container-block">
                        <div className="related-rent-product-title-section">{d.title}</div>
                        <div className="related-rent-product-discription-section">{d.subtitle}</div>
                        <div className="related-rent-product-price-section">
                        <div className="related-rent-product-price1">
                        <div className="related-rent-product-buy">{d.buy} </div>
                                    <div className="related-rent-product-buy-amount">{d.amount}</div>
                            </div>
                            <div className="related-rent-product-price2">{d.rent}</div>
                        </div>
                    </div>
                    <div className="related-rent-product-image-section">
                        <div className="related-rent-product-image">
                            <img src={d.image} alt='' />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default RelatedRentProduct;