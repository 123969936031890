import CartSelectNewItems from './CartNewSelectItem/index';
import CartHeader from '../ShoppingCart/CartHeader';
import CartPayment from '../ShoppingCart/CartPayment';
import FooterPage from '../Footer';

import React, { useState } from 'react';



function CartNewPage () {
        const [cartItems, setCartItems] = useState([
            {
                productID:"asd",
                image: process.env.PUBLIC_URL + '/Assests/cartnewselectitems/gym.png',
                title: 'Gym home workout',
                price:120,
                purchaseType:"Rent",
                quantity: 1,
                delevaryCharges:50,
                decreaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/minus.png',
                increaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/plus.png',
            },
            
        ]);
    
        const increaseItems = (i) => {
            let _tmpItems = [...cartItems]
            _tmpItems[i].quantity += 1
            setCartItems(_tmpItems);
        };
    
        const decreaseItems = (i) => {
            let _tmpItems = [...cartItems]
            if (_tmpItems[i].quantity > 1)
                _tmpItems[i].quantity -= 1
            setCartItems(_tmpItems);
        };
    
    return (

        <div className="cart-new-page">
            <CartHeader />
            <CartSelectNewItems cartItems={cartItems} increaseItems={increaseItems} decreaseItems={decreaseItems}/>
            <CartPayment cartItems={cartItems} />
            <FooterPage />
        </div>
    );
}

export default CartNewPage; 