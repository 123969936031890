import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("initial load");
  }, []);

  return (
    <div
      style={{
        // position: "absolute",

        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />

      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          padding: 20,
          textAlign: "justify",
        }}
      >
        <h2>Refund Policy:</h2>
        <p>
          {" "}
          <b>Unsatisfactory Experience:</b> We strive to provide the best
          service and ensure customer satisfaction. If you had an unsatisfactory
          experience with our sports equipment rental, please reach out to our
          customer support team within 2 days of the rental date. We will
          investigate the matter and, if valid, provide a refund for the rental
          amount.
        </p>
        <p>
          <b> Unavailability of Product:</b> In the event that the requested
          sports equipment is not available at the selected facility, we will
          notify you as soon as possible. If an alternative arrangement cannot
          be made, we will initiate a refund for the rental amount. Refund
          Process: Refunds will be processed within [1] working days from the
          date of approval.
        </p>

        <p>
          <b></b>Please note that it may take an additional [3] working days for
          the refund to reflect in your account, depending on your bank or
          payment provider. Refund Eligibility: To be eligible for a refund, the
          customer must adhere to our rental terms and conditions, return the
          equipment in its original condition, and provide proof of the issue or
          unavailability. Contacting Customer Support: If you need assistance or
          have any questions regarding refunds, please contact our customer
          support team at hello@sprentzo.com. We are here to help resolve any
          concerns promptly.
        </p>
      </div>
      <MianFooter />
    </div>
  );
};
export default Home;
