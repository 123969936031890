import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";
import CategoriesList from "../../components/v2/CategoriesList";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useState } from "react";
import badmintonData from "../../data/badminton.json";
import cricket from "../../data/cricket.json";
import football from "../../data/football.json";
import { useEffect } from "react";
import { get } from "../../apiIntegrations/fetcher";
// import HomeMainBanner from "../../components/v2/HomeMainBanner";

const Search = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [sportsSelected, setSportsSelected] = useState("");
  const [products, setProducts] = useState([]);
  useEffect(() => {
    let bFilter = localStorage.getItem("filter");
    setSportsSelected(bFilter);
    loadData(bFilter);
  }, []);
  async function loadData(filter) {
    console.log(filter);
    try {
      const res = await get("sports?name=" + filter?.toLowerCase());
      console.log(res);
      let response = null;
      if (res.data.length === 1)
        response = await get("/products?limit=200&sports=" + res.data[0]._id);
      else response = await get("/products?limit=200&");
      console.log(response);
      setProducts(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div
      style={{
        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
        }}
      >
        <div style={{ borderTop: "1px SOLID #f1f1f1", padding: 0 }}>
          <CategoriesList
            sportsSelected={sportsSelected}
            onChange={(val) => {
              setSportsSelected(val);
              loadData(val);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "1px solid #ccc",
              color: "#666",
            }}
          >
            {/* <div
              onClick={(e) => setSelectedFilter("all")}
              style={{
                margin: "5px 25px",
                marginBottom: 0,
                borderBottom:
                  selectedFilter === "all"
                    ? "4px solid #e25556"
                    : "4px solid #fff",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              All
            </div>
            <div
              onClick={(e) => setSelectedFilter("rent")}
              style={{
                margin: "5px 25px",
                marginBottom: 0,
                borderBottom:
                  selectedFilter === "rent"
                    ? "4px solid #e25556"
                    : "4px solid #fff",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              To Rent
            </div> */}
            {/* <div
              onClick={(e) => setSelectedFilter("buy")}
              style={{
                margin: "5px 25px",
                marginBottom: 0,
                borderBottom:
                  selectedFilter === "buy"
                    ? "4px solid #e25556"
                    : "4px solid #fff",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              To Buy
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {products.length === 0 && (
              <div style={{ padding: 100, color: "#888" }}>
                No Products Found
              </div>
            )}
            {products.map((product, i) => (
              <div key={product._id}>
                <ProductCard
                  _id={product._id}
                  name={product.name}
                  rental_price_on_app={product.rent}
                  actual_price={product.buy}
                  image={product.images[0]}
                  stock={product.stock}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <MianFooter />
    </div>
  );
};
export default Search;

const ProductCard = ({
  _id,
  name = "",
  actual_price,
  rental_price_on_app,
  image,
  stock,
}) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        minWidth: 140,
        maxWidth: 180,
        width: 180,
        border: "1px solid #f1f1f1",
        flex: 1,
        height: 350,
      }}
      onClick={(e) => navigate("/discription/" + _id)}
    >
      <div
        style={{
          height: 200,
          padding: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          alt="boot"
          // width="100%"
          style={{ maxWidth: "80%" }}
        />
      </div>
      <div
        style={{ fontSize: 12, fontWeight: 500, color: "#222", padding: 10 }}
      >
        {name.substring(0, 20)}
        {name.length > 20 ? "..." : ""}
      </div>
      {(stock === 0 || !stock) && (
        <div
          style={{
            background: "#ccc",
            padding: 4,
            color: "#fff",
            textAlign: "center",
          }}
        >
          Coming Soon
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div>
          <div style={{ padding: 10, fontSize: 12, color: "#666" }}>Buy</div>
          <div
            style={{ padding: 10, paddingTop: 0, fontSize: 14, color: "#333" }}
          >
            <strike style={{ color: "red" }}>
              <span style={{ color: "#888", fontWeight: 600 }}>
                ₹{actual_price}
              </span>
            </strike>
          </div>
        </div>
        <div>
          <div style={{ padding: 10, fontSize: 12, color: "#333" }}>Rent</div>
          <div
            style={{
              padding: 10,
              paddingTop: 0,
              fontSize: 14,
              color: "#333",
              fontWeight: 600,
            }}
          >
            ₹{rental_price_on_app}
          </div>
        </div>
      </div>
    </div>
  );
};
