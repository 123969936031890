import CartHeader from "./CartHeader/index";
import CartSelectItems from "./CartSelectItems/index";
import CartPayment from "./CartPayment/index";
import MianFooter from "../../components/v2/MianFooter";
import MainHeader from "../../components/v2/MainHeader";
import React, { useEffect, useState } from "react";
import { apiEndpoint } from "../../apiIntegrations/fetcher";
import { get, post, patch } from "../../apiIntegrations/fetcher";
import isLoggedIn from '../../utils/isLoggedIn.js'
import NewLoginForm from '../../components/NewLoginForm/index.jsx'
function ShoppingCartPage() {
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState({});
  const [openLoginForm, setOpenLoginForm] = useState(false)
  const simulateCart = (operation, state) => {
    // if not loggedin then we use this function
    const offlineCart = !localStorage.getItem('offlineCart') ? localStorage.getItem('offlineCart')
      : JSON.parse(localStorage.getItem('offlineCart'))
    // eslint-disable-next-line default-case
    switch (operation) {
      case 'INIT':
        if (!offlineCart) {
          localStorage.setItem('offlineCart', JSON.stringify({ items: [] }))
          setCart({ items: [] })
          setItems([])
          break
        }
        setCart(offlineCart)
        setItems(offlineCart.items)
        break
      case 'PATCH':
      case 'DELETE':
        localStorage.setItem('offlineCart', JSON.stringify(state))
        setCart({ ...state, random: new Date().getTime() })
        setItems(state.items)
        break
    }
  }

  const [showPolicy, setShowPolicy] = useState(false);
  useEffect(() => {

    if (!isLoggedIn()) {
      localStorage.setItem('routeToRedirect', '/cart')
      setOpenLoginForm(true)
      return
    }
    loadCart();
  }, []);
  async function loadCart() {
    if (!isLoggedIn()) {
      alert('still not logged in')
      simulateCart('INIT')
      return
    }
    const offlineCart = !localStorage.getItem('offlineCart') ? null : JSON.parse(localStorage.getItem('offlineCart'))
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    const res = await get(`carts?user=${userProfile._id}`)
    console.log("fetchedCart length " + JSON.stringify(res?.data[0]?.items?.length))
    let newItems = []
    if (!offlineCart) {
      newItems = res.data[0].items
    }
    if (offlineCart) {
      console.log("offlineCart before length: " + offlineCart?.items?.length)
      newItems = res?.data[0]?.items.map(eachItem => {
        const itemIndex = offlineCart.items.findIndex(offlineItem => offlineItem?.product?._id === eachItem?.product?._id)
        if (itemIndex === -1) {
          return eachItem
        }
        const updatedItem = { ...eachItem, quantity: offlineCart.items[itemIndex].quantity + eachItem.quantity }
        offlineCart.items.splice(itemIndex, 1);
        return updatedItem
      })
      console.log("offlineCart after length: " + offlineCart?.items?.length)
      newItems = [...newItems, ...offlineCart.items]
      await patch(`carts/${res.data[0]._id}`, { ...offlineCart, items: newItems });
      localStorage.removeItem('offlineCart')
    }
    console.log("newItems length: " + newItems.length + " newItems:  " + JSON.stringify(newItems))
    setItems(newItems);
    setCart({ ...res.data[0], items: newItems });
  }
  const handleDelete = async (i) => {
    console.log();
    cart.items = cart.items.filter((d) => d.product._id !== i);
    console.log(i);
    if (!isLoggedIn()) {
      simulateCart('DELETE', cart)
      return
    }

    await patch(`carts/${cart._id}`, cart);
    loadCart();
  };

  const increaseItems = async (i) => {
    cart.items.map((d) => {
      if (d.product._id === i) d.quantity = d.quantity + 1;
    });
    console.log(i);
    if (!isLoggedIn()) {
      simulateCart('PATCH', cart)
      return
    }
    await patch(`carts/${cart._id}`, cart);
    loadCart();
  };

  const decreaseItems = async (i) => {
    cart.items.map((d) => {
      if (d.product._id === i) {
        if (d.quantity === 1) {
          handleDelete(i);
        }
        if (d.quantity > 1) d.quantity = d.quantity - 1;
      }
    });
    console.log(i);
    if (!isLoggedIn()) {
      simulateCart('PATCH', cart)
      return
    }
    await patch(`carts/${cart._id}`, cart);
    loadCart();
  };

  const handlePostLogin = () => {
    setOpenLoginForm(false)
    loadCart();
  }
  return (
    <div
      style={{
        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
          // background: "#f1f1f1",
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: 20,
            color: "#7868ab",
            fontWeight: 700,
            fontSize: 22,
          }}
        >
          Cart
        </div>
        <CartSelectItems
          items={items}
          increaseItems={increaseItems}
          decreaseItems={decreaseItems}
          handleDelete={handleDelete}
        />
        <CartPayment
          cart={cart}
          items={items}
          displayRazorpay={displayRazorpay}
        />
      </div>
      <div
        style={{ padding: 20, textAlign: "center" }}
        onClick={(e) => setShowPolicy(true)}
      >
        <a href="#">Shipping Policy</a>{" "}
      </div>
      {showPolicy && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "#cccccc96",
            zIndex: 9999,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              background: "#fff",
              margin: 20,
              position: "relative",
              zIndex: 9999,
              padding: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", marginBottom: 20 }}>
              <span style={{ fontSize: 20, fontWeight: 700, flex: 1 }}>
                Shipping Policy
              </span>
              <span
                style={{ color: "#666" }}
                onClick={(e) => setShowPolicy(false)}
              >
                Close
              </span>
            </div>
            <div style={{ flex: 1, overflow: "scroll" }}>
              At Sprentzo Sports Pvt. Ltd, we strive to provide a seamless and
              convenient rental experience for our customers. This Shipping
              Policy outlines the procedures and guidelines for shipping and
              receiving our sports equipment. By renting our equipment, you
              agree to the terms of this Shipping Policy.
              <h3>Shipping Methods</h3>
              <p>
                <b>1.1 At Venue pickups:</b> Our sports equipment is available
                for pickup directly at the designated sports venues. You can
                conveniently collect the equipment at the specified location and
                time provided during the reservation process. Use it there and
                return.
              </p>
              <h3>Rental Period and Return</h3>
              <p>
                <b>2.1 Rental Period:</b> The rental period starts on the
                scheduled pickup date, time and ends on the designated return
                date, time. Please ensure that you return the equipment on or
                before the agreed return date to avoid any late fees or
                penalties.
              </p>
              <p>
                <b>2.2 Return Process:</b> After using the equipment, please
                return it to the same venue where you picked it up. Our staff
                will verify the condition of the equipment and check for any
                damages. Once the inspection is completed, your rental will be
                considered returned.
              </p>
              <h3>Condition of Equipment</h3>
              <p>
                <b>3.1 Well-Maintained Equipment:</b> We take pride in providing
                high-quality and well-maintained sports equipment. Our team
                thoroughly inspects and cleans the equipment after each rental
                to ensure it is in optimal condition for the next user.
              </p>
              <p>
                <b>3.2 Damage or Loss:</b> We expect our customers to handle the
                equipment with care. Any damage, loss, or theft of the rented
                equipment during the rental period will be the responsibility of
                the customer. Please inform us immediately if any damage or loss
                occurs.
              </p>
              <h3>Late Returns</h3>
              <p>
                <b>4.1 Late Fees:</b> If you fail to return the equipment on the
                agreed return date and time, late fees may apply. The late fee
                will be charged based on the hourly rental rate until the
                equipment is returned.
              </p>
              <p>
                <b>4.2 Extended Rental:</b> If you wish to extend the rental
                period, please contact us in advance to make arrangements.
                Additional rental charges may apply for the extended duration.
              </p>
              <h3>Shipping Restrictions</h3>
              <p>
                <b>5.1 Venue-Specific Pickup:</b> Please note that our equipment
                is only available for pickup at the designated sports venues. We
                currently do not offer shipping or delivery to individual
                addresses.
              </p>
              Customer Support If you have any questions, concerns, or require
              assistance regarding the shipping or return of our sports
              equipment, please contact our customer support team at{" "}
              <b style={{ color: "blue" }}>hello@sprentzo.com</b>. We are here
              to help and ensure a smooth rental experience for you.
            </div>
          </div>
        </div>
      )}
      <NewLoginForm openLoginForm={openLoginForm} handlePostLogin={handlePostLogin} />
      <MianFooter cart={cart} />
    </div>
  );
}
async function displayRazorpay(cart, _amount, redirectTo) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // creating a new order
  // const result = await post("http://localhost:5001/rorders", {
  const result = await post(`${apiEndpoint}rorders`, {
    cart: cart._id,
    amount: _amount,
  });

  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  // Getting the order details back
  console.log(JSON.stringify(result));
  const { amount, id: order_id, currency } = result.razOrder;
  redirectTo(result.order);

  const options = {
    key: "rzp_live_4lB51KHOq4XdkI", // prod
    // key: "rzp_test_17zGoDKC3vb6uc",
    // "rzp_test_17zGoDKC3vb6uc", // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "Sprentzo",
    description: "Sprentzo Transaction",
    // image: { logo },
    order_id: order_id,
    handler: async function (response) {
      const data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };

      // const result = await axios.post("http://localhost:5000/payment/success", data);
      // alert(result.data.msg);
      localStorage.removeItem('offlineCart')
    },
    prefill: {
      // name: "Soumya Dey",
      // email: "SoumyaDey@example.com",
      // contact: "9999999999",
    },
    notes: {
      // address: "Soumya Dey Corporate Office",
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export default ShoppingCartPage;
