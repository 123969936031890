import logo from "../../../assets/SprentzoLogo.svg";
import downRed from "../../../assets/icons/downRed.svg";
import locationIcon from "./location.png";
import venue from "../../../data/venue.json";
import arrowLeft from "../MainHeader/arrow-left.png";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../../apiIntegrations/fetcher";
import { useLocation } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const _location = useLocation();
  console.log(_location.pathname, "=================================================>>>>>>>>>>>")
  const currentPathname = _location.pathname;
  const hideBackButtonPaths = ['/home', '/cart'];
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("Loading ..");
  useEffect(() => {
    // let l = {};
    // venue.map((v) => (l[v.location] = ""));
    loadLocations();
    const l = localStorage.getItem("location");
    setLocation(l || "not Selected");
    // setLocations(l);
  }, []);
  async function loadLocations() {
    const res = await get("venues?limit=50");
    console.log(res.data);
    setLocations(res.data);
  }
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  return (
    <div
      style={{
        height: 85,
        boxShadow: "0px 2px 14px #a68f8f4a",
        position: "relative",
        zIndex: 999,
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 10,
          flex: 1,
          // flexDirection: "column",
        }}
        onClick={(e) => navigate("/home")}
      >
        <div style={{ color: "#444" }}>
          {!hideBackButtonPaths.includes(currentPathname) && (
            <img src={arrowLeft} alt="Logo" height="24" width="24" onClick={() => navigate(-1)} />
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt={"SPRENTZO"} height={35} />
        </div>
      </div>
      {/* <div style={{ flex: 1 }}> </div> */}
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          fontWeight: "600",
          color: "#e25556",
          marginRight: 6,
          marginTop: 3,
          // display:"none"
        }}
      >
        <img src={downRed} width={10} />
      </div> */}
      {/* <div
        onClick={(e) => setShowLocationSelector(true)}
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 14,
          fontWeight: "600",
          color: "#e25556",
        }}
      >
        {location}
      </div>
      {showLocationSelector && (
        <div
          style={{
            position: "fixed",
            zIndex: 999,
            height: "80%",
            background: "rgb(255 255 255)",
            width: "calc( 100% - 60px )",
            top: 45,
            display: "flex",
            flexDirection: "column",
            boxShadow: "5px 7px 14px 4px #77707082",
            borderRadius: 5,
            padding: 20,
            margin: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: 12,
              padding: 10,
              paddingLeft: 0,
            }}
          >
            <div style={{ flex: 1 }}>Select Location</div>
            <div onClick={(e) => setShowLocationSelector(false)} style={{fontSize:20}}>close</div>
          </div>
          <div style={{ flex: 1, overflow: "scroll" }}>
            {(locations).map((v) => {
              return (
                <div
                  onClick={(e) => {
                    setLocation(v.name);
                    localStorage.setItem("location",v.name)
                    setShowLocationSelector(false);
                  }}
                  style={{ padding: 5 }}
                >
                  {v.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 30,
          marginRight: 20,
        }}
      >
        <img src={locationIcon} alt={"Location"} />
      </div> */}
    </div>
  );
};
export default Header;
