import sprentzo from "../../assets/SprentzoLogo.svg";
import React, { useState, useEffect, useRef } from "react";
import { signup, login } from "../../apiIntegrations/auth";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Modal from '../../components/Modal'
import modalContent from '../../modalContent'
const SignUp = () => {
  const { registrationFailed } = modalContent
  const [open, setOpen] = useState(false)
  const checkboxRef = useRef(null);
  const phoneRef = useRef(null);
  const handleOpen = () => {
    setOpen(false)
    if (emailOrPhoneInvalid) {
      phoneRef.current.focus()
    }
    if (!aggree)
      checkboxRef.current.focus()
  }
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailOrPhoneInvalid, setEmailOrPhoneInvalid] = useState(false)
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [aggree, setAggree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(null);

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", overflow: "scroll" }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            background: "#fff",
            flexDirection: "column",
            maxWidth: 500,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // marginTop: 80,
              padding: 40,
            }}
          >
            <img src={sprentzo} alt="sprentzoimg" height={40} />
          </div>
          <div
            style={{
              flex: 1,
              //   border: "1px solid red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                border: "0px solid red",
                width: "90%",
                padding: 10,
                background: "#fff",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  name="firstName"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  className="log-input"
                  placeholder="First Name"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  name="lastName"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  className="log-input"
                  placeholder=" Last Name"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ color: "#888", marginTop: 10, marginRight: 7 }}>
                  +91
                </span>
                <input
                  name="phoneNo"
                  ref={phoneRef}
                  value={phone}
                  // onChange={(event) => setPhone(event.target.value)}
                  onChange={(event) => {
                    setPhone(event.target.value);
                     setRes(null);
                    }}
                  className="log-input"
                  style={{ width: 265 }}
                  placeholder="Phone Number"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  name="email"
                  value={email}
                  //onChange={(event) => setEmail(event.target.value)}
                  onChange={(event) => {
                    setEmail(event.target.value)
                    setRes(null);
                  }}
                  className="log-input"
                  placeholder="Email"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className="log-input"
                  placeholder="Password"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 20px",
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <input
                    id="agreeCheckbox"
                    type="checkbox"
                    checked={aggree}
                    ref={checkboxRef}
                    onChange={(e) => {
                      console.log(e);
                      setAggree(e.target.checked);
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <div>
                    <a
                      onClick={(e) => navigate("/terms")}
                      style={{ color: "#999", fontSize: 14 }}
                    >
                      By signing up, you agree to the terms of service and our
                      privacy policy.
                    </a>
                  </div>
                  <div>
                    {/* <a onClick={(e) => navigate("/privacy-policy")}>
                    {" "}
                    Privacy Policy
                  </a> */}
                  </div>
                </div>{" "}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    //   border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    //   textAlign: "center",
                    width: "80%",
                    margin: 10,
                    padding: 15,
                    fontWeight: 300,
                    borderRadius: 5,
                    background: aggree ? (loading ? "#ddd" : "#7868ab") : "#ddd",
                    color: aggree ? (loading ? "#7868ab" : "#ddd") : "#7868ab",
                    marginTop: 20,
                    // color: "#fff",
                  }}
                  onClick={async () => {
                    if (!aggree)
                      setOpen(true)
                    setLoading(true);
                    let res = await signup({
                      firstName,
                      lastName,
                      email,
                      password,
                      phone,
                    });
                    setRes(res);
                    if (res.error) {
                      setLoading(false);
                      setOpen(true)
                      setEmailOrPhoneInvalid(true)
                    } else {
                      res = await login({ userId: phone, password });
                      if (res.error) {
                        setEmailOrPhoneInvalid(true)
                        console.log("asdadsfasdf", res);
                      } else {
                        localStorage.setItem("Authorization", res.token);
                        navigate("/home");
                      }
                      console.log(res);
                      setLoading(false);
                    }
                  }}
                >
                  <span
                    style={{
                      fontSize: 23,
                    }}
                  >
                    {loading
                      ? "Please wait creating your acount!"
                      : "Create Account"}
                  </span>
                </div>
              </div>
              <div style={{color:'red',justifyContent:'center',alignItems:"center", display:"flex"}}>{res && res.errordata}</div>
            </div>
          </div>

          <div style={{ maxHeight: 200, textAlign: "center", padding: 10 }}>
            <div style={{ fontSize: 12, color: "#999" }}>
              Already have an account?
            </div>
            <span
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: "#555",
                color: "#7566a3",
              }}
              onClick={(e) => {
                navigate("/login");
              }}
            >
              Login
            </span>
          </div>
        </div>
      </div>
      <Modal open={open} message={registrationFailed.message} icon={registrationFailed.icon}
        description={registrationFailed.description}
        backgroundColor={registrationFailed.backgroundColor}
        color={registrationFailed.color} handleOpen={handleOpen} />
    </>
  );
};
export default SignUp;
