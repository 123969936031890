import React, { useState, useEffect } from "react";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios"; // import axios library

import Slider from "react-slick";
import { Link } from "react-router-dom";

function ProductCard() {
  const [productCardsData, setProductCardsData] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:3003/sports")
      .then((response) => {
        //console.log(response.data.data)
        setProductCardsData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //console.log(productCardsData.sport_name)
  return (
    <div className="home-product-cards-container">
      <Slider {...settings}>
        {productCardsData.map((d, i) => (
          <div className="home-product-card-block" key={i}>
            <Link to="/product" style={{ textDecoration: "none" }}>
              <div className="product-image">
                <img
                  src={
                    d.images[0] ||
                    "http://localhost:3000/static/media/ftball.cc9bf42ea9311d2710f7.png"
                  }
                  alt=""
                  width={70}
                  height={70}
                />
              </div>
              <div
                className="product-title"
                style={{ textTransform: "capitalize" }}
              >
                {d.name}
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ProductCard;
