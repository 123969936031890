import React from "react";
import "./index.css";
import deleteicon from "../../../assets/icons/delete.svg";
import { getLocaleHoursAndMinutesString } from "../../../utils/dateTime"
function CartSelectItems({
  items,
  cartItems,
  decreaseItems,
  increaseItems,
  handleDelete,
}) {

  return (
    <div className="cart-select-item-container">
      {items.length === 0 && (
        <div
          style={{
            textAlign: "center",
            padding: 10,
            fontSize: 20,
            fontWeight: 500,
            color: "#666",
          }}
        >
          No items added yet
        </div>
      )}
      {items.map((d, i) => (
        <div className="p-container" key={i}>
          <div
            key={"item" + i}
            className="cart-select-item-block"
            style={{
              background: "#fff",
              paddingBottom: 100,
              // boxShadow: "1px 1px 1px 1px #cccccc4d",
              borderBottom: "1px solid #eee",
            }}
          >
            <div
              className="cart-select-item-image"
              style={{ background: "#fff" }}
            >
              <img src={d.product.images[0]} alt="" width={60} height={60} />
            </div>
            <div className="cart-select-items">
              <div
                className="cart-select-item-title"
                style={{ fontSize: 14, fontWeight: 500 }}
              >
                {d.product.name?.substr(0, 20)}
                {d.product.name.length > 20 ? "..." : ""}
              </div>
              <div className="cart-select-item-price">
                {d?.product?.type === 'Event' ? (
                  <span>
                    {console.log(d)}
                    Buy: <b style={{ fontSize: 18 }}>₹{d.config.price}</b>
                  </span>
                ) : (
                  <span>
                    Rent:{" "}
                    <b style={{ fontSize: 18 }}>
                      ₹
                      {d.product.rent +
                        (((d.config?.duration - 90) / 30) * 25) / 100 * d.product.rent}
                    </b>
                  </span>
                )}
              </div>
              <div className="cart-select-item-price">
                {"Time Slot:"}{" "}
                <b style={{ fontSize: 13, fontWeight: 500 }}>
                  {new Date(Date.parse(d.config?.timeSlot)).toLocaleString('en-US', {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: 'numeric', minute: 'numeric', hour12: true
                  })}
                </b>
              </div>
              <div className="cart-select-item-price">
                {d?.product?.type !== 'Event' && (
                  <span>
                    {"Duration:"}{" "}
                    <b style={{ fontSize: 13, fontWeight: 500 }}>
                      {getLocaleHoursAndMinutesString(d.config?.duration)}
                    </b>
                  </span>)}
              </div>
              <div className="cart-select-item-price">
                {"Venue:"}{" "}
                <b style={{ fontSize: 13, fontWeight: 500 }}>
                  {d.venue?.name}
                </b>
              </div>
              {d.size && <div className="cart-select-item-price">
                {"Size:"}{" "}
                <b style={{ fontSize: 13, fontWeight: 500 }}>
                  {d.size}
                </b>
              </div>}
            </div>
            <div
              className="cart-select-item-no"
              style={{ background: "#fff" }}
            >
              <button
                style={{ background: "#fff" }}
                className="cart-select-decrease"
                onClick={(e) => {
                  console.log("decrease");
                  decreaseItems(d.product._id);
                }}
              >
                -
              </button>
              <p
                className="cart-select-items-value"
                style={{
                  width: 30,
                  textAlign: "center",
                  position: "relative",
                  top: -4,
                }}
              >
                <b>{d.quantity}</b>
              </p>
              <button
                className="cart-select-increase"
                style={{ background: "#fff" }}
                onClick={(e) => {
                  console.log("increase");
                  increaseItems(d.product._id);
                }}
              >
                +
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 10,
              position: "relative",
              top: 8,
            }}
          >
            <img
              src={deleteicon}
              alt=""
              // className="delete-icon"
              width={"20"}
              onClick={() => handleDelete(d.product._id)}
            />
          </div>
        </div >
      ))
      }
      {
        items.length > 0 && <div style={{ 'padding': '0px 20px' }}>
          In the case of a product being unavailable due to any circumstance at a Sprentzo venue. You will get a product of the same quality or better as a replacement.
        </div>
      }
    </div >
  );
}

export default CartSelectItems;
