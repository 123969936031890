import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";

import close from "../../assets/close.svg";
import "./index.css";
import { useEffect, useState } from "react";
import { get, patch, post } from "../../apiIntegrations/fetcher";

const WishList = () => {
  const [list, setList] = useState([]);
  const [wishList,setWishList] = useState({})
  useEffect(() => {
    loadWishlist();
  }, []);
  async function loadWishlist() {
    let profile = JSON.parse(localStorage.getItem("userProfile"));
    let res = await get("wishlists?user=" + profile._id);
    console.log(res);
    if (res.count === 0) {
      const cRes = await post("wishlists", { products: [], user: profile._id });
      console.log(cRes);
    } else {
      setList(res.data[0].products);
      
    }
    res = await get("wishlists?user=" + profile._id);
    setWishList(res.data[0] ||{})

  }
  async function removeProduct(id){
    console.log(wishList,list.filter(d=>d._id !== id))

    const res =await  patch("wishlists/"+wishList._id,{
      products:list.filter(d=>d._id !== id).map(d=>d._id)
    })
    console.log(res)
    loadWishlist();

  }
  return (
    <div
      style={{
        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
          // background: "#f1f1f1",
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: 20,
            color: "#E15456",
            fontWeight: "600",
          }}
        >
          Wishlist
        </div>
        <div>
          {list.length === 0 && (
            <div
              style={{
                padding: 100,
                fontSize: 20,
                color: "#555",
                textAlign: "center",
              }}
            >
              No products added yet
            </div>
          )}
          {list.length > 0 &&
            list.map((items, i) => {
              return (
                <div
                  key={i}
                  style={{
                    borderBottom: "1px solid rgb(204 204 204 / 22%)",
                    margin: 20,
                    padding: 10,
                    display: "flex",
                    borderRadius: 5,
                    background: "#fff",
                    // boxShadow: "1px 2px 2px 0px #cccccc96",
                  }}
                >
                  <div style={{ border: "1px solid #eee", borderRadius: 10 }}>
                    <img
                      src={items.images[0]}
                      alt={items.name}
                      width={100}
                      style={{ margin: 10 }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        padding: 20,
                        paddingTop: 0,
                        paddingBottom: 0,
                        color: "#444",
                      }}
                    >
                      {items.name}
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        padding: 20,
                        paddingTop: 10,
                        paddingBottom: 0,
                        color: "#666",
                      }}
                    >
                      ₹{items.rent}
                    </div>
                    {/* <div style={{ padding: 20 }}>
                    <button
                      style={{
                        background: "#fff",
                        border: "1px solid #ccc",
                        padding: "10px 20px",
                        color: "#444",
                        borderRadius: 6,
                      }}
                    >
                      Add to cart
                    </button>
                  </div> */}
                  </div>
                  <div
                    onClick={(e) => {
                      console.log("removing Items");
                      removeProduct(items._id)
                    }}
                  >
                    <img src={close} width="12" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <MianFooter />
    </div>
  );
};
export default WishList;
