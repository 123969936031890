import React from "react";
import "./index.css";
import ftball from "../SimilarProducts/images/ftball.png";
import kneepad from "../SimilarProducts/images/knee.png";
import helmet from "../SimilarProducts/images/helmet.png";
import tshirt from "../SimilarProducts/images/t.png";
import badmiton from "../SimilarProducts/images/batm.png";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

let NewSimilarProductData = [
  {
    image: ftball,
    title: "Football",
    buy: "Buy",
    amount: "₹800",
    rent: "Rent ₹120",
  },
  {
    image: kneepad,
    title: "Sports Knee Cap",
    buy: "Buy",
    amount: "₹400",
    rent: "Rent ₹120",
  },
  {
    image: helmet,
    title: "Water sports Helmet",
    buy: "Buy",
    amount: "₹400",
    rent: "Rent ₹80",
  },
  {
    image: tshirt,
    title: "Sports Jersy",
    // buy: 'Buy ₹400',
    rent: "Buy ₹500",
  },
  {
    image: badmiton,
    title: "Tennis Bat",
    buy: "Buy",
    amount: "₹1000",
    rent: "Rent ₹150",
  },
];
function SimilarProducts({ sectionTitle }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="home-similar-product-title-container">
        <div className="Home-similar-product-title">{sectionTitle} </div>
        <Link
          to="/product"
          style={{ textDecoration: "none", color: "#E15456" }}
        >
          <div className="Home-similar-product-subtitle">view all </div>
        </Link>
      </div>
      {/* <div className="home-similar-product-container"> */}
      <Slider {...settings}>
        {NewSimilarProductData.map((d, i) => (
          // <Link
          //   to="/discription"
          //   style={{ textDecoration: "none", color: "#E15456" }}
          // >
          <div
            className="home-similar-product-block"
            key={i}
            onClick={(e) => navigate("/discription/" + d.title)}
          >
            <div className="home-similar-product-image">
              <img src={d.image} alt="" />
            </div>
            <div className="home-similar-product-title">{d.title}</div>
            <div className="home-similar-product-price">
              <div className="product-price1">
                <div className="product-buy">{d.buy} </div>
                <div className="product-buy-amount">{d.amount}</div>
              </div>
              <div className="product-price2">{d.rent}</div>
            </div>
          </div>
          // </Link>
        ))}
      </Slider>
      {/* </div> */}
    </>
  );
}

export default SimilarProducts;
