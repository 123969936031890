import React from "react";
import { Link } from "react-router-dom";
import './index.css';

function SearchProduct({ SearchProductItems }) {
    return (
        <>
            <div className="search-product-cards-container">
                {SearchProductItems.map((d, i) => (
                    <div key={i} className="search-product-card-block" >
                        <Link to='/searchlist' style={{ textDecoration:'none' }}>
                            <div className="search-product-container" style={{ background: d.background }}>
                                <div className="search-product-name">{d.title}</div>
                                <div className="search-product-image">
                                    <img src={d.image} alt="" width={130} height={130} />
                                </div>
                            </div>
                        </Link>
                    </div>

                ))}
            </div>
        </>
    )
}

export default SearchProduct;