import React, { useState, useEffect } from "react";
import "./index.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function NewArrival() {
  const [newProductCardsData, setNewProductCardsData] = useState([]);

  useEffect(() => {
    fetch("http://localhost:3003/sports")
      .then((response) => response.json())
      .then((data) => setNewProductCardsData(data.data));
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    centerMode: true,
    centerPadding: "20%",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="Home-product-title">New arrival and Best Selling </div>
      <div className="home-new-product-cards-container">
        <Slider {...settings}>
          {[
            {
              image:
                "https://media.istockphoto.com/id/185097876/photo/treadmill.jpg?s=612x612&w=0&k=20&c=gndW2QG1T5v5I0U5hRTiF3-Yw-yIzB9yOUVu74CVIls=",
            },
            {
              image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP4rgGMLRrBG0GglWF4l70ngGMCOf_ZfIA_8589VylHCJzcjt5gDqQEhTaBiD10ggyU_dXkyhRAhw&usqp=CAU&ec=48600113",
            },
            {
              image:
                "https://media.gettyimages.com/id/182713174/photo/dumbells.jpg?s=612x612&w=gi&k=20&c=BwWrRn4Y3piE7RBK2gjXV-d5WAzWSnXeryJivavhBvk=",
            },
          ].map((d, i) => (
            <div className="home-new-product-card-block" key={i}>
              <Link to="/discription">
                <div
                  className="new-product-image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={d.image}
                    alt=""
                    width={180}
                    height={180}
                    // className="new-product-image"
                  />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default NewArrival;
