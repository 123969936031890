const dateObject = new Date()
const getCurrentYear = () => dateObject.getFullYear()
const getCurrentMonth = () => dateObject.getMonth()
const getCurrentDate = () => dateObject.getDate()
const getCurrentLocalTime = () => { return { hours: dateObject.getHours(), minutes: dateObject.getMinutes() } }

const monthsConciseArray = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
const checkNext30MinuteInterval = () => {
    const { hours, minutes } = getCurrentLocalTime();
    if (minutes >= 0 && minutes <= 29) {
        return { hours, minutes: 30 }
    }
    if (minutes >= 30 && minutes <= 59) {
        if (hours < 23) {
            return { hours: hours + 1, minutes: 0 }
        }
        if (hours === 23) {
            return { hours: 0, minutes: 0 }
        }
    }
}
const getSelectedMonthLastDate = (yearSelected, monthSelected) => {
    const monthsLastDate = { 'JAN': 31, 'FEB': (year) => checkLeapYear(year) ? 29 : 28, 'MAR': 31, 'APR': 30, 'MAY': 31, 'JUN': 30, 'JUL': 31, 'AUG': 31, 'SEP': 30, 'OCT': 31, 'NOV': 30, 'DEC': 31 }
    const lastDate = monthsLastDate[monthsConciseArray[monthSelected]]
    return typeof lastDate === 'function' ? lastDate(yearSelected) : lastDate
}

const checkLeapYear = (year) => ((0 === year % 4) && (0 !== year % 100)) || (0 === year % 400)

const getISODateTime = (dateTime) => { const { year, month, date, hours, minutes } = dateTime; return new Date(year, month, date, hours, minutes).toISOString() }
const getLocaleString = (dateTime) => {
    const { year, month, date, hours, minutes } = dateTime; return new Date(year, month, date, hours, minutes).toLocaleString('en-US', {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: 'numeric', minute: 'numeric', hour12: true
    }
    )
}

const convertMinutestoHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
}

const getLocaleHoursAndMinutesString = (duration) => {
    const { hours, minutes } = convertMinutestoHoursAndMinutes(duration)
    console.log(hours, minutes)
    let hourString = "", minuteString = "";
    if (hours > 1) {
        hourString = hours + " hours";
    } else if (hours === 1) {
        hourString = hours + " hour";
    }

    if (minutes > 1) {
        minuteString = minutes + " minutes";
    } else if (minutes === 1) {
        minuteString = minutes + " minute";
    }

    if (hourString && minuteString) {
        return hourString + " and " + minuteString
    }
    if (hourString) {
        return hourString
    }
    if (minuteString) {
        return minuteString
    }
    return " N/A "
}

export {
    getCurrentYear,
    getCurrentMonth,
    getCurrentDate,
    getCurrentLocalTime,
    monthsConciseArray,
    checkNext30MinuteInterval,
    checkLeapYear,
    getSelectedMonthLastDate,
    getISODateTime,
    getLocaleString,
    convertMinutestoHoursAndMinutes,
    getLocaleHoursAndMinutesString
}
