import homeIcon from "../../../assets/icons/home.svg";
import searchIcon from "../../../assets/icons/search.svg";
import starIcon from "../../../assets/icons/star.svg";
import cartIcon from "../../../assets/icons/cart.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import FooterLinkTile from "./FooterLinkTile";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "../../../apiIntegrations/fetcher";
let interval = null;
const Footer = (props) => {
  const [cart, setCart] = useState(null);
  const [wishList, setWishList] = useState(null);
  useEffect(() => {
    loadCW();
    // interval = setInterval(() => {
    //   console.log("calling cart and wish list");
    //   loadCW();
    // }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [props?.qty, props?.cart]);

  async function loadCW() {
    let profile = JSON.parse(localStorage.getItem("userProfile"));
    const cres = profile ? await get("carts?user=" + profile._id) : { data: [{ items: [] }] }
    let wres = profile ? await get("wishlists?user=" + profile._id) : { data: [{ products: [] }] }
    console.log(cres, wres);
    setCart(cres.data[0]?.items?.length || 0);
    setWishList(wres.data[0]?.products?.length || 0);
  }
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: 60,
        display: "flex",
        position: "relative",
        zIndex: 999,
        boxShadow: "0px -3px 11px 2px #665f5f5e",
      }}
    >
      {[
        {
          title: "Home",
          icon: homeIcon,
          link: "/home",
        },
        {
          title: "Search",
          icon: searchIcon,
          link: "/search",
        },
        {
          title: "Wishlist",
          icon: starIcon,
          link: "/wishlist",
          badge: wishList,
        },
        {
          title: "Cart",
          icon: cartIcon,
          link: "/cart",
          badge: cart,
        },
        {
          title: "Profile",
          icon: profileIcon,
          link: "/profile",
        },
      ].map((link, i) => {
        return (
          <FooterLinkTile
            onClick={(e) => navigate(link.link)}
            key={"footerTile" + i}
            title={link.title}
            icon={link.icon}
            badge={link.badge}
          />
        );
      })}
    </div>
  );
};
export default Footer;
