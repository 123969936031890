import sprentzo from "./logo1.png";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { login } from "../../apiIntegrations/auth";
import "./index.css";
const TermsAndCondetions = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          background: "#fff",
          flexDirection: "column",
          maxWidth: 500,
        }}
      >
        <dv
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={sprentzo} alt="sprentzoimg" height={90} />
        </dv>
        <div
          style={{ marginLeft: 20, fontWeight: 600, fontSize: 20 }}
          onClick={(e) => navigate("/signup")}
        >
          <svg
            fill="#000000"
            version="1.1"
            id="Capa_1"
            width="30px"
            height="30px"
            viewBox="0 0 400.004 400.004"
          >
            <g>
              <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757   c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072   c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315   C400.004,190.438,392.251,182.686,382.688,182.686z" />
            </g>
          </svg>
          <span style={{ position: "relative", top: -8, marginLeft: 10 }}>
            Back
          </span>
        </div>

        <div
          style={{
            flex: 1,
            overflow: "scroll",
            overflowX: "hidden",
            padding: 20,
            textAlign: "justify",
          }}
        >
          <h2>Terms and Conditions:</h2>
          <p>
            <b>Rental Agreement:</b> By using our rental service, customers
            agree to abide by the terms and conditions outlined herein.
          </p>
          <p>
            <b>Eligibility:</b> Customers must have valid government proof to
            rent sports equipment.
          </p>

          <p>
            <b>Booking and Payment:</b> Customers can book equipment through our
            web app. Full payment is required at the time of booking to secure
            the rental. Accepted payment methods include UPI, Net Banking,
            Credit Cards, Debit Cards
          </p>
          <p>
            <b>Equipment Usage:</b> Customers are responsible for using the
            rented equipment safely and as intended. Any damage caused by
            misuse, negligence, or intentional actions will be the customer's
            responsibility.
          </p>

          <p>
            <b>Deposits:</b> Some products may require deposits, which will have
            to be made at the time of taking the rental.
          </p>

          <p>
            <b>Rental Period:</b> The rental period begins at the designated
            start time and ends at the designated return time as decided on the
            app.{" "}
          </p>

          <p>
            <b>Equipment Return:</b> Customers must return the rented equipment
            in the same condition as received, with normal wear and tear
            expected. Late returns may incur 50% additional charges.
          </p>

          <p>
            <b>Cancellations and Refunds:</b> Cancellations made 2 hours prior
            to the rental date and time will be eligible for a full refund.
            Cancellations made within 75 minutes of the rental date and time may
            be subject to a cancellation fee. Any cancellation made 30 minutes
            within the rental date and time will not be eligible for any
            returns.{" "}
          </p>

          <p>
            <b>Liability and Waiver:</b> Customers assume full responsibility
            for any injuries, damages, or losses incurred while using the rented
            equipment. By using our rental service, customers agree to release
            Sprentzo Sports Private Limited and its affiliates from any
            liability.
          </p>

          <p>
            <b>Availability:</b> While we strive to ensure the availability of
            the requested equipment, there may be instances when certain items
            are not available due to unforeseen circumstances. In such cases, we
            will make reasonable efforts to provide suitable alternatives or
            offer a full refund.
          </p>

          <p>
            <b>Compliance with Rules and Regulations:</b> Customers must comply
            with the rules and regulations of the respective sports facilities
            where the equipment is being rented. Any violation may result in the
            termination of the rental agreement without refund.
          </p>

          <p>
            <b>Modifications and Amendments:</b> We reserve the right to modify
            or amend these terms and conditions at any time. Customers will be
            notified of any changes via the appropriate communication channels.
          </p>
        </div>
      </div>
    </div>
  );
};
export default TermsAndCondetions;
