// import FooterPage from "../Footer";
import UserLocation from "./UserLocation";
import UserPayment from "./UserPayment";
import UserProfileInfo from "./UserProfileInfo";
import UserSetting from "./UserQuery";
import { useNavigate } from "react-router-dom";
// function UserProfilePage () {
//     return (
//         <div className="profile-section">
//             <UserProfileInfo />
//             <UserLocation />
//             <UserPayment />
//             <UserSetting />
//             <FooterPage />
//         </div>
//     )

// }

// export default UserProfilePage;

import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";

const Home = () => {
  const navigate = useNavigate();
  const openFAQs = () => {
    window.open('https://www.sprentzo.com/#faqs');
  };
  return (
    <div
      style={{
        // position: "absolute",

        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >

      <MainHeader />




      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
        }}
      >
        <UserProfileInfo />
        {/* <UserLocation /> */}
        <div
          style={{ padding: 10, background: "#f1f1f1", margin: 15 }}
          onClick={(e) => navigate("/orders")}
        >
          Orders
        </div>
        <div
          style={{ padding: 10, background: "#f1f1f1", margin: 15 }}
          onClick={(e) => navigate("/termsofusage")}
        >
          Terms and Conditions
        </div>
        <div
          style={{ padding: 10, background: "#f1f1f1", margin: 15 }}
          onClick={(e) => navigate("/refundPolicy")}
        >
          Refund Policy
        </div>
        <Mailto email={"hello@sprentzo.com"} subject={"Support mail"} body="">
          <div style={{ padding: 10, background: "#f1f1f1", margin: 15 }}>
            Contact US
          </div>
        </Mailto>
        <div
          style={{ padding: 10, background: "#f1f1f1", margin: 15, cursor: 'pointer' }}
          onClick={openFAQs}>
          FAQs
        </div>
        {/* <UserPayment /> */}
        <UserSetting />
      </div>
      <div style={{ padding: 20, fontSize: 14 }}>
        <h3> Write to us</h3>
        <div>
          <h4 style={{ margin: 0 }}> SPRENTZO SPORTS PRIVATE LIMITED HD-026</h4>
          <div>WeWork Galaxy</div>
          <div>#43, Residency Road, Shantala Nagar, Ashok Nagar</div>
          <div>Bangalore - 560025</div>
        </div>
      </div>
      <MianFooter />
    </div>
  );
};
export default Home;
const Mailto = ({ email, subject, body, children }) => {
  return (
    <a
      style={{ color: "#222", textDecoration: "none" }}
      href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
    >
      {children}
    </a>
  );
};
