import React from "react";
import "./index.css";

function RelatedAllGymProduct({ NewAllGymProductData }) {
  return (
    <>
      <div className="related-all-gym-product-container">
        {NewAllGymProductData.map((d, i) => (
          <div className="related-all-gym-product-block">
            <div className="related-all-gym-product-image">
              <img src={d.image} alt="" />
            </div>
            <div
              className="related-all-gym-product-title"
              style={{ padding: 5 }}
            >
              {d.title?.substring(0, 15)}
              {d.title.length > 15 ? "..." : ""}
            </div>
            <div className="related-all-gym-product-price">
              <div className="related-all-gym-price1">
                <div
                  className="related-all-gym-buy"
                  style={{ marginBottom: 5 }}
                >
                  Buy{" "}
                </div>
                <div className="related-all-gym-buy-amount">{d.amount}</div>
              </div>
              <div className="related-all-gym-price2">{d.rent}</div>
            </div>
            <div className="related-gym-product-button">
              <button className="product-discover-button">Discover</button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default RelatedAllGymProduct;
