import React from "react";
import "./index.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let NewProductCardsData = [
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedProductItems/shoe.png",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedProductItems/ball.png",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedProductItems/shoe.png",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedProductItems/ball.png",
  },
];

function RelatedProductItem() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    arrows: false,
    // centerMode: true,
    centerPadding: "20%",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-discription-items-container">
      <div className="product-discription-items-title">Related Product</div>
      <div className="product-discription-items-cards-container">
        <Slider {...settings}>
          {NewProductCardsData.map((d, i) => (
            <div className="product-discription-items-card-block" key={i}>
              <div
                className="product-discription-items-image-container"
                // style={{ border: "2px solid red" }}
              >
                <img
                  src={d.image}
                  alt=""
                  className="product-discription-items-image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default RelatedProductItem;
