import axios from "axios";

export const apiEndpoint = "https://api.sprentzo.com/";
// export const apiEndpoint = "http://localhost:5001/";
// export const apiEndpoint = "https://9d59-2409-408c-8408-1919-d073-bfa-7e2-b56.ngrok-free.app/";

const axiosInstance = axios.create({
  baseURL: apiEndpoint,
});

const get = async (path, params = {}, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.get(path, {
      params: params,
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });

    return response.data;
  } catch (error) {
    await axiosInstance.post('error-log', { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });

    throw error;
  }
};

const post = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.post(path, data, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });

    return response.data;
  } catch (error) {
    await axiosInstance.post('error-log', { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });
    throw error;
  }
};

const put = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.put(path, data, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });

    return response.data;
  } catch (error) {
    await axiosInstance.post('error-log', { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });
    throw error;
  }
};
const patch = async (path, data = null, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.patch(path, data, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });
    return response.data;
  } catch (error) {
    await axiosInstance.post('error-log', { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });
    throw error;
  }
};

const del = async (path, headers = {}) => {
  const Authorization = localStorage.getItem("Authorization");
  try {
    const response = await axiosInstance.delete(path, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });

    return response.data;
  } catch (error) {
    await axiosInstance.post('error-log', { error: { name: error.name, stack: error.stack, cause: error.cause, message: error.message } }, {
      headers: { ...headers, Authorization, 'ngrok-skip-browser-warning': 'true' },
    });
    throw error;
  }
};

export { get, post, put, patch, del };
