import ProductMianImages from "../../components/v2/ProductMianImages";
import MianFooter from "../../components/v2/MianFooter";
import MainHeader from "../../components/v2/MainHeader";
import { useState } from "react";
import down from "../../assets/icons/down-arrow-purple.svg";
import upArrow from "../../assets/icons/up-arrow-purple.svg";
import downWhite from "../../assets/icons/down-arrow-white.svg";
import upArrowWhite from "../../assets/icons/up-arrow-white.svg";
import { Routes, Route, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { addToWishlist } from "../../apiIntegrations/wishListHandlers";
import { get, post, patch } from "../../apiIntegrations/fetcher.js";
import { useEffect } from "react";
import { getCurrentYear, getCurrentMonth, monthsConciseArray, checkNext30MinuteInterval, getCurrentDate, getSelectedMonthLastDate, getISODateTime, getLocaleString, getLocaleHoursAndMinutesString } from "../../utils/dateTime"
import { range } from "../../utils/range"
import modalContent from '../../modalContent'
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import isLoggedIn from '../../utils/isLoggedIn.js'

const ProductDetails = ({ }) => {
  const navigate = useNavigate();
  const { addedToCart, addedToWishlist } = modalContent
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(false)
  }
  const [openWishlistModal, setOpenWishlistModal] = useState(false)
  const handleOpenWishlistModal = () => {
    setOpenWishlistModal(false)
  }
  const [purchaseMode, setPurchaseMode] = useState("rent");
  const [expandedDetails, setExpandedDetails] = useState(1);
  const [expandedSize, setExpandedSize] = useState(1);
  let { id } = useParams();
  const [product, setProduct] = useState({});
  const [venues, setVenues] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState(null);

  const [loadingCartBtn, setLoadingCartBtn] = useState(false);
  const getTimeSlotStartingTime = checkNext30MinuteInterval()

  const getHoursRange = (dateTime) => {
    const { year, month, date } = dateTime
    if (year === getCurrentYear() && month === getCurrentMonth() && date === getCurrentDate()) {
      return range(getTimeSlotStartingTime.hours, 24)
    }
    return range(0, 24)

  }
  const MIN_DURATION = 90
  const DURATION_STEP = 30
  const MAX_DURATION = 180
  const TIME_PERIODS = ['AM', 'PM']
  const [dateTime, setDateTime] = useState({
    year: getCurrentYear(),
    month: getCurrentMonth(),
    date: getCurrentDate(),
    hours: getTimeSlotStartingTime.hours,
    minutes: getTimeSlotStartingTime.minutes,
    timePeriod: 'AM',
    duration: MIN_DURATION
  });

  const getSelectedMonthDateRange = (dateTime) => {
    const { year, month } = dateTime
    if (year === getCurrentYear() && month === getCurrentMonth()) {
      return range(getCurrentDate(), getSelectedMonthLastDate(year, month) + 1)
    }
    return range(1, getSelectedMonthLastDate(year, month) + 1)

  }

  const [quantity, setQuantity] = useState(1);
  const [inCart, setIncart] = useState();
  const [cart, setCart] = useState({});
  const [qty, setQty] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    // if (!localStorage.getItem('Authorization')) {
    //   localStorage.clear()
    //   navigate('/login')
    // }

    if (id) {
      loadProduct(id);
      loadCart(id);
    }
  }, []);

  const simulateCart = (operation, state) => {
    // if not loggedin then we use this function
    const offlineCart = !localStorage.getItem('offlineCart') ? localStorage.getItem('offlineCart')
      : JSON.parse(localStorage.getItem('offlineCart'))
    let itemInCart = []
    // eslint-disable-next-line default-case
    switch (operation) {
      case 'INIT':
        // if not loggedin then we use this function
        // instead of getting cart from db, we need to load it from localStorage,
        // we create a cart structure for the initial load if not available in localStorage and then save it in state and localStorage
        if (!offlineCart) {
          console.log('itemInCart 108')
          localStorage.setItem('offlineCart', JSON.stringify({ items: [] }))
          setCart({ items: [] })
          break
        }
        // every time the page loads we need to check the product is in the cart, if it does we set setIncart, setQty
        itemInCart = offlineCart.items.filter((d) => d.product._id === id);
        console.log('itemInCart 112', offlineCart, itemInCart)
        setCart(offlineCart);
        if (itemInCart.length > 0) {
          setIncart(true);
          setQty(itemInCart[0].quantity);
        } else {
          setIncart(false);
        }
        break
      // every time we add product to the cart, the item must have config, product, venue and size
      case 'ADD':
        let res = offlineCart.items.filter((e) => {
          return e.product._id === product._id;
        });
        let items = [];
        let quantityToSet = 0
        if (res.length === 0) {
          quantityToSet = 1
          items = [
            ...offlineCart.items.map((d) => ({
              ...d,
            })),
            {
              product,
              quantity: 1,

              config: {
                timeSlot: getISODateTime(dateTime), duration: dateTime.duration,
                price: product.type === 'Event' ? selectedOption === 'men' ? product.prices.men : product.prices.women : 0,
              },
              venue: selectedVenues,
              size: selectedSize,
            },
          ];
        } else {
          items = [
            ...offlineCart.items.map((d) => {
              let qty = d.quantity; //+quantity
              if (product._id === d.product._id) {
                qty = d.quantity + quantity;
                quantityToSet = qty;
              }
              return {
                ...d,
                quantity: qty,
                config: { timeSlot: getISODateTime(dateTime), duration: dateTime.duration },
                venue: selectedVenues,
                size: selectedSize,
              };
            }),
          ];
        }
        setIncart(true)
        setQty(quantityToSet)
        setCart({ items })
        localStorage.setItem('offlineCart', JSON.stringify({ items }))
        break
      // after post, patch, delete, use the current state mgmt and then save it in  it in localStorage but don't need to call loadCart again
      //every time we increase or decrease quantity, let's just save the cart
      case 'PATCH':
      case 'DELETE':
        localStorage.setItem('offlineCart', JSON.stringify(state))
        // every time the page loads we need to check the product is in the cart, if it does we set setIncart, setQty
        itemInCart = state.items.filter((d) => d.product._id === id);
        if (itemInCart.length > 0) {
          setIncart(true);
          setQty(itemInCart[0].quantity);
        } else {
          setIncart(false);
        }
        break
    }
  }
  async function loadCart(pid) {
    try {
      if (!isLoggedIn()) {
        simulateCart('INIT')
        return
      }
      const userProfile = JSON.parse(localStorage.getItem("userProfile"));
      //check the cart present or not
      const carts = await get(`carts?user=${userProfile._id}`);
      let cp = carts.data[0].items.filter((d) => d.product._id === pid);
      if (cp.length > 0) {
        setIncart(true);
        setQty(cp[0].quantity);
      } else {
        setIncart(false);
      }
      console.log('cart structure for state =====>', carts.data[0])
      setCart(carts.data[0]);
    } catch (e) {
      setIncart(false);
    }
  }
  const handleDelete = async (i) => {
    cart.items = cart.items.filter((d) => d.product._id !== i);
    if (!isLoggedIn()) {
      simulateCart('DELETE', cart)
      return
    }
    console.log('cart structure for db delete =====>', cart)
    await patch(`carts/${cart._id}`, cart);
    loadCart(id);
  };

  const increaseItems = async (i) => {
    cart.items.map((d) => {
      if (d.product._id === i) {
        d.quantity = d.quantity + 1;
        setQty(d.quantity);
      }
    });
    if (!isLoggedIn()) {
      simulateCart('PATCH', cart)
      return
    }
    console.log('cart structure for db increase =====>', cart)
    await patch(`carts/${cart._id}`, cart);
    loadCart(id);
  };

  const decreaseItems = async (i) => {
    cart.items.map((d) => {
      if (d.product._id === i) {
        if (d.quantity === 1) {
          handleDelete(i);
        }
        if (d.quantity > 1) {
          d.quantity = d.quantity - 1;
          setQty(d.quantity);
        }
      }
    });
    if (!isLoggedIn()) {
      simulateCart('PATCH', cart)
      return
    }
    console.log('cart structure for db decrease =====>', cart)
    await patch(`carts/${cart._id}`, cart);
    loadCart(id);
  };
  async function loadProduct() {
    let _sport = null;

    const res = await get(`/products/${id}`);
    const sport = res?.sports?.[0]?.name
    const venuRes = await get(`/venues?sports=${sport}&limit=50`);
    if (res?.type === 'Event' && venuRes.data && venuRes.data.length) {
      setSelectedVenues(venuRes.data?.[0]?._id)
      setDateTime({ date: 30, duration: 90, hours: 18, minutes: 0, month: 11, timePeriod: "PM", year: 2023 })
    }
    console.log("dateTime", dateTime)
    setVenues(venuRes.data);

    setProduct(res);
  }
  const [selectedSize, setselectedSize] = useState(null);

  const shoeSizes = ['UK6', 'UK7', 'UK8', 'UK9', 'UK10', 'UK11']

  const glovesSizes = ['7', '9', '11']

  const sizesArray = product && product.type && product.type.match(/\b(?:Boots|Shoe)\b/i) ? shoeSizes : glovesSizes

  const handleTabClick = (tabName) => {
    console.log(tabName)
    setselectedSize(tabName);
  };
  return (
    <>
      {product && 'type' in product &&
        <div
          style={{
            maxWidth: 500,
            margin: "auto",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            background: "#fff",
          }}
        >
          <MainHeader />

          <div
            style={{
              flex: 1,
              overflow: "scroll",
              overflowX: "hidden",
              paddingBottom: 20,
            }}
          >
            <ProductMianImages images={product.images} />
            <div
              style={{
                padding: 20,
                fontSize: 20,
                fontWeight: 600,
                color: "#444",
                textAlign: "center",
                paddingBottom: 0,
              }}
            >
              {product.name}
            </div>

            {!(product.stock >= 1) && (
              <div
                style={{
                  padding: 10,
                  fontSize: 20,
                  fontWeight: 400,
                  letterSpacing: 2.8,
                  textAlign: "center",
                  color: "#fff",
                  background: "rgb(255 89 50 / 86%)",
                  margin: 30,
                }}
              >
                Coming Soon
              </div>
            )}
            {product.stock >= 1 && (
              <>
                {" "}
                <div
                  onClick={(e) => setPurchaseMode("rent")}
                  style={{
                    padding: 10,
                    color: "#fff",
                    background: product.type === 'Event' ? "#1dab65" : "#e25556",
                    margin: 20,
                    marginBottom: 10,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px"
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {product.type === 'Event' ? 'I want to be the most fun cricketer ever' : `Rent at closest sports venue - Rs.${product.rent}`}
                  </div>
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      background: purchaseMode === "rent" ? "#FFD83B" : "#fff",
                      borderRadius: 100,
                    }}
                  ></div>
                </div>
                {/* <div
              style={{
                padding: 10,
                color: "#fff",
                background: "#5098D3",
                margin: 20,
                marginTop: 0,
                display: "flex",
                alignItems: "center",
              }}
              onClick={(e) => setPurchaseMode("buy")}
            >
              <div style={{ flex: 1 }}>Buy - Rs.{product.buy}</div>
              <div
                style={{
                  height: 15,
                  width: 15,
                  background: purchaseMode === "buy" ? "#FFD83B" : "#fff",
                  borderRadius: 100,
                }}
              ></div>
            </div> */}
              </>
            )}

            {product.type !== 'Event' && (<DetailsCard
              title={"Select Venue To Play In"}
              showDetails={expandedDetails === 0}
              expand={(e) => {
                //setExpandedDetails(1);
                setExpandedDetails(expandedDetails === 1 ? 0 : 1);
              }}
              Details={() => {
                return (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#555",
                      letterSpacing: 0.6,
                      padding: 20,
                    }}
                  >
                    {/* {JSON.stringify(product.sports[0].name)} */}
                    {/* {product &&
                    product.sports &&
                    product.sports[0].name === "badminton" && (
                      <div>
                        <div style={{ fontSize: 16 }}> Launching soon</div>
                        <a
                          style={{ color: "#222", textDecoration: "none" }}
                          href={`mailto:${"hello@sprentzo.com"}?subject=${encodeURIComponent("Venue request") || ""
                            }&body=${encodeURIComponent("") || ""}`}
                        >
                          <button
                            className="login-get-me-back-button"
                            style={{
                              letterSpacing: 1,
                              padding: 10,
                              fontSize: 13,
                              margin: 10,
                              marginLeft: 0,
                            }}
                          >
                            Drop us a message
                          </button>
                        </a>
                      </div>
                    )} */}
                    {product &&
                      product.sports &&
                      venues.map((d, i) => (
                        <div
                          onClick={(e) => setSelectedVenues(d._id)}
                          style={{
                            padding: 10,
                            borderBottom: "1px solid #ccc",
                            display: "flex",
                          }}
                          key={i}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                fontSize: 14,
                                color: "#222",
                                fontWeight: 500,
                                marginBottom: 5,
                              }}
                            >
                              {d.name}
                            </div>
                            {d.area}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                height: 15,
                                width: 15,
                                background:
                                  selectedVenues === d._id ? "#FFD83B" : "#ccc",
                                borderRadius: 100,
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                  </div>
                );
              }}
            />)}
            {product && product.type && product.type.match(/\b(?:Boots|Shoe|Gloves)\b/i) && (
              <SelectSize
                title={"Select Sizes"}
                showSize={expandedSize === 0}
                expandsize={() => {
                  setExpandedSize(expandedSize === 1 ? 0 : 1);
                }}
                SizeSelect={() => {
                  return (
                    <div
                      style={{
                        fontSize: 16,
                        color: "#555",
                        letterSpacing: 0.6,
                        padding: 10,
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', height: '6vh', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
                        {sizesArray.map(size =>
                          <div
                            key={size}
                            style={{
                              flex: '1',
                              backgroundColor: selectedSize === size ? 'rgb(120, 104, 171)' : '#ddd',
                              padding: '5px',
                              borderRadius: '5px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              color: selectedSize === size ? '#ffffff' : 'rgb(120, 104, 171)'
                            }}
                            onClick={() => handleTabClick(size)}
                          >
                            {size}
                          </div>
                        )}
                      </div>


                    </div>
                  );
                }}
              />
            )}

            <div style={{ padding: '0px 20px' }}>
              <h3 style={{ marginBottom: '10px' }}>Time Slot</h3>
              <div>
                {product.type === 'Event' ? (<div>
                  30 December, 6PM Onwards<br />
                  {venues[0].name}, {venues[0].area}
                </div>) : (
                  <div>
                    Select Date:&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }}>
                      <option>{getCurrentYear()}</option>
                    </select>&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }} value={dateTime.month} onChange={e => { setDateTime(dateTime => { return { ...dateTime, month: e.target.value } }) }}>
                      {monthsConciseArray.slice(getCurrentMonth(), 12).map((month, index) => <option value={index + getCurrentMonth()}>{month}</option>)}
                    </select>&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }} value={dateTime.date} onChange={e => { setDateTime(dateTime => { return { ...dateTime, date: e.target.value } }) }}>
                      {getSelectedMonthDateRange(dateTime).map((date) => <option>{date < 10 ? "0" + date : date}</option>)}
                    </select>&nbsp;&nbsp;
                    &nbsp;
                    <br /><br />
                    Select Time:&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }} value={dateTime.hours} onChange={e => { setDateTime(dateTime => { return { ...dateTime, hours: e.target.value } }) }}>
                      {getHoursRange(dateTime).filter(hour => dateTime.timePeriod === 'PM' ? hour < 12 : hour >= 12).map(hour => <option value={hour}>{hour === 0 ? 12 : hour < 10 ? "0" + hour : hour > 12 ? hour - 12 < 10 ? "0" + (hour - 12) : (hour - 12) : hour}</option>)}
                    </select>&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }} value={dateTime.minutes} onChange={e => { setDateTime(dateTime => { return { ...dateTime, minutes: e.target.value } }) }}>
                      {["00", 30].map(minutes => <option>{minutes}</option>)}
                    </select>&nbsp;&nbsp;
                    <select style={{ fontSize: "15px" }} value={dateTime.timePeriod} onChange={e => { setDateTime(dateTime => { return { ...dateTime, timePeriod: e.target.value } }) }}>
                      {(!(getHoursRange(dateTime).some(hour => hour < 12)) ? ['PM'] : TIME_PERIODS).map(timePeriod => <option>{timePeriod}</option>)}
                    </select>
                    <br /><br /><br />Duration:&nbsp;
                    <button style={{ background: "inherit", borderRadius: "50%" }} onClick={() => {
                      setDateTime(dateTime => {
                        return { ...dateTime, duration: dateTime.duration > MIN_DURATION ? dateTime.duration - DURATION_STEP : MIN_DURATION }
                      })
                    }}> - </button>&nbsp;&nbsp;&nbsp;
                    {dateTime.duration}
                    &nbsp;&nbsp;&nbsp;<button style={{ background: "inherit", borderRadius: "50%" }} onClick={() => {
                      setDateTime(dateTime => {
                        return { ...dateTime, duration: dateTime.duration !== MAX_DURATION ? dateTime.duration + DURATION_STEP : MAX_DURATION }
                      })
                    }}> + </button>
                    <br /><br />Booked on {getLocaleString(dateTime)} for {getLocaleHoursAndMinutesString(dateTime.duration)}
                  </div>)}
              </div>
            </div>
            {product.type === 'Event' &&
              <div style={{ margin: 20 }}>
                <label>
                  <input
                    type="radio"
                    value="men"
                    checked={selectedOption === 'men'}
                    onChange={() => handleOptionChange('men')}
                  />
                  Man: ₹ {product.prices.men}
                </label>

                <label>
                  <input
                    type="radio"
                    value="women"
                    checked={selectedOption === 'women'}
                    onChange={() => handleOptionChange('women')}
                  />
                  Woman: ₹ {product.prices.women}
                </label>
              </div>}
            <div style={{ display: "flex" }}>
              {product.stock >= 1 && (
                <div style={{ flex: 1 }}>
                  {!inCart && (
                    <div
                      onClick={async (e) => {
                        if (loadingCartBtn)
                          return alert("Please wait adding in progress");

                        try {
                          if (!selectedVenues) return alert("Select the VENUE");
                          if (product && product.type && product.type.match(/\b(?:Boots|Shoe|Gloves)\b/i) && !selectedSize) return alert("Select the size");
                          if (!dateTime) return alert("Select the time slot");
                          if (product && product.type === 'Event' && !selectedOption) return alert("Select ticket price (Men/Women)");
                          if (!isLoggedIn()) {
                            setLoadingCartBtn(true);
                            setOpen(true)
                            simulateCart('ADD')
                            setLoadingCartBtn(false);
                            return
                          }
                          const userProfile = JSON.parse(
                            localStorage.getItem("userProfile")
                          );
                          //check the cart present or not
                          const carts = await get(`carts?user=${userProfile._id}`);

                          let cart = null;
                          // if not create one
                          if (carts.count === 0) {
                            const _cart = await post(
                              `carts?user=${userProfile._id}`,
                              {
                                products: [],
                                user: userProfile._id,
                              }
                            );
                            console.log('cart structure post creation of cart =====>', _cart)
                            cart = _cart;
                          } else {
                            cart = carts.data[0];
                          }

                          let res = cart.items.filter((e) => {
                            return e.product._id === product._id;
                          });
                          let items = [];

                          if (res.length === 0) {
                            items = [
                              ...cart.items.map((d) => ({
                                ...d,
                                product: d.product._id,
                              })),
                              {
                                product: product._id,
                                quantity: 1,

                                config: {
                                  timeSlot: getISODateTime(dateTime), duration: dateTime.duration,
                                  price: product.type === 'Event' ? selectedOption === 'men' ? product.prices.men : product.prices.women : 0,
                                },
                                venue: selectedVenues,
                                size: selectedSize,
                              },
                            ];
                          } else {
                            items = [
                              ...cart.items.map((d) => {
                                let qty = d.quantity; //+quantity
                                if (product._id === d.product._id)
                                  qty = d.quantity + quantity;
                                return {
                                  ...d,
                                  product: d.product._id,
                                  quantity: qty,
                                  config: { timeSlot: getISODateTime(dateTime), duration: dateTime.duration },
                                  venue: selectedVenues,
                                  size: selectedSize,
                                };
                              }),
                            ];
                          }
                          setLoadingCartBtn(true);
                          setOpen(true)
                          console.log('cart structure addToCart =====>', items)
                          await patch(`carts/${cart?._id}`, {
                            items,
                          });
                          loadCart(id);

                          setLoadingCartBtn(false);
                        } catch (e) {
                          console.log(e)
                          alert("Error adding Item1", e);
                          setLoadingCartBtn(false);
                        }
                      }}
                      style={{
                        padding: 10,
                        margin: 15,
                        border: "1px solid #5098d3",
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#fff",
                        borderRadius: 5,
                        background: "#7868ab",
                        flex: 1,
                        maxWidth: product.type === 'Event' ? '100px' : '100%'
                      }}
                    >
                      {!loadingCartBtn ? "Add to cart" : "Please wait ! "}
                    </div>
                  )}
                  {inCart && (
                    <div style={{ padding: 20, display: "flex" }}>
                      {product.type !== 'Event' && (<div
                        style={{
                          height: 40,
                          width: 40,
                          fontSize: 20,
                          border: "1px solid #555",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          if (qty > 0) {
                            decreaseItems(id);
                          }
                        }}
                      >
                        -
                      </div>)}
                      <div
                        style={{
                          height: 40,
                          width: product.type === 'Event' ? 100 : 40,
                          border: "1px solid #555",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {qty}
                      </div>
                      {product.type !== 'Event' && (<div
                        style={{
                          height: 40,
                          width: 40,
                          fontSize: 20,
                          border: "1px solid #555",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => increaseItems(id)}
                      >
                        +
                      </div>)}
                    </div>
                  )}
                </div>
              )}
              {product.type !== 'Event' && (
                <div
                  onClick={async (e) => {
                    try {
                      addToWishlist(product._id);
                      setOpenWishlistModal(true)
                    } catch (e) {
                      alert("Error adding Item2", e);
                    }
                  }}
                  style={{
                    padding: 10,
                    margin: 15,
                    border: "1px solid #5098d3",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#5098d3",
                    borderRadius: 5,
                    flex: 1,
                  }}
                >
                  Add to Wishlist
                </div>)}
              <div style={{ display: "none" }}>
                <div
                  style={{
                    // padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 15,
                    marginRight: 0,
                    border: "1px solid #ccc",
                    width: 40,
                    height: 40,
                    borderRadius: 30,
                  }}
                  onClick={(e) => {
                    if (quantity > 1) setQuantity(quantity - 1);
                  }}
                >
                  <span style={{ position: "relative", top: -3 }}>-</span>
                </div>
                <div
                  style={{
                    // padding: 10,
                    // margin: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border: "1px solid #ccc",
                    width: 40,
                  }}
                >
                  {quantity}
                </div>
                <div
                  style={{
                    // padding: 10,
                    margin: 15,
                    marginLeft: 0,
                    border: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 40,
                    height: 40,
                    borderRadius: 30,
                  }}
                  onClick={(e) => {
                    setQuantity(quantity + 1);
                  }}
                >
                  <span style={{ position: "relative", top: -3 }}>+</span>
                </div>
              </div>
            </div>
            <h3 style={{ marginLeft: 10, marginBottom: 0, paddingLeft: 10, }}>Description</h3>
            <div
              style={{
                fontSize: 12,
                color: "#555",
                letterSpacing: 0.6,
                padding: 20,
                paddingTop: 0
              }}
            >
              {/* <ReactMarkdown>{product.description}</ReactMarkdown> */}
              <h3 dangerouslySetInnerHTML={{ __html: product.description }}></h3>
            </div>
            {/* <div>
            <div
              style={{
                marginTop: 30,
                padding: 10,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 600,
                color: "#333",
              }}
            >
              Similar Products
            </div>
            <div
              style={{
                padding: 40,
                fontSize: 10,
                color: "#999",
                textAlign: "center",
              }}
            >
              loading ...
            </div>
          </div> */}
          </div>

          <MianFooter qty={qty} cart={cart} />
        </div >}
      <Modal open={open} message={addedToCart.message} icon={addedToCart.icon}
        description={addedToCart.description}
        backgroundColor={addedToCart.backgroundColor}
        color={addedToCart.color} handleOpen={handleOpen} />
      <Modal open={openWishlistModal} message={addedToWishlist.message} icon={addedToWishlist.icon}
        description={addedToWishlist.description}
        backgroundColor={addedToWishlist.backgroundColor}
        color={addedToWishlist.color} handleOpen={handleOpenWishlistModal} />
    </>
  );
};
export default ProductDetails;

const DetailsCard = ({
  title,
  showDetails,
  expand,
  Details = () => <div></div>,
}) => {
  return (
    <div
      style={{
        margin: 20,
        padding: 1,
        borderBottom: "1px solid #eee",
        borderRadius: 5,
      }}
    >
      <div style={{ display: "flex", backgroundColor: '#f3cc1b', borderRadius: '5px', padding: '10px 10px', alignItems: 'center' }} onClick={expand}>
        <div style={{ fontSize: 18, fontWeight: 600, flex: 1, color: "rgb(120, 104, 171)" }}>
          {title}
        </div>
        <div>
          {showDetails ? (
            <img src={upArrow} width="10" />
          ) : (
            <img src={down} width="15" />
          )}
        </div>
      </div>
      {showDetails && <Details></Details>}
    </div>
  );
};


const SelectSize = ({
  title,
  showSize,
  expandsize,
  SizeSelect = () => <div></div>,
}) => {
  return (
    <div
      style={{
        margin: 20,
        padding: 1,
        borderBottom: "1px solid #eee",
        borderRadius: 5,
      }}
    >
      <div style={{ display: "flex", backgroundColor: 'rgb(120, 104, 171)', borderRadius: '5px', padding: '10px 10px', alignItems: 'center' }} onClick={expandsize}>
        <div style={{ fontSize: 18, fontWeight: 600, flex: 1, color: "#fff" }}>
          {title}
        </div>
        <div>
          {showSize ? (

            <img src={upArrowWhite} width="10" />

          ) : (
            <img src={downWhite} width="15" />
          )}
        </div>
      </div>
      {showSize && <SizeSelect></SizeSelect>}
    </div>
  );
};