import FooterPage from "../Footer";
import SimilarProducts from "./SimilarProducts";
import HomeBanner from "./HomeBanner";
import HomePageHeader from "./HomeHeader";
import NewArrival from "./NewArrivals";
import ProductCard from "./ProductCard";
import HomepageBrowserButton from "./HomeButton";

function HomePage() {
  return (
    <div className="homepage-container">
      <HomePageHeader />
      <HomepageBrowserButton />
      <HomeBanner />
      <ProductCard />
      <NewArrival />
      <SimilarProducts sectionTitle={"Top Pics for you"} />
      <SimilarProducts sectionTitle={"Best selling"} />
      <SimilarProducts sectionTitle={"New products"} />
      <FooterPage />
    </div>
  );
}

export default HomePage;
