import React from "react";
import { Link } from "react-router-dom";
import homeimg from "../HomeBanner/images/image1.png";
import "./index.css";

function HomeBanner() {
  return (
    <div className="home-page-image-container">
      {/* <Link to='/sales' > */}
      <img src={homeimg} alt="homeimg" width="100%" />
      {/* </Link> */}
    </div>
  );
}

export default HomeBanner;
