import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Sprentzologo from "./components/FlashScreen";
import HomePage from "./pages/Home";
import SearchPage from "./pages/Search";
import WishListPage from "./pages/Wishlist";
import CartPage from "./pages/Cart";
import OrderPage from "./pages/Orders";
import SearchSportsPage from "./pages/SearchSports";
import SearchEventsPage from "./pages/SearchEvents";
import SearchResltPage from "./pages/SearchResult";
import UserProfile from "./pages/UserProfile";
import ProductSetails from "./pages/ProductDetails";

import HomePage1 from "./components/HomePage";
import SearchListPage from "./components/SearchListPage";
import SalesPage from "./components/SalesPage";
import RelatedProductPage from "./components/RelatedProduct";
import SearchListContentPage from "./components/SearchList";
import CartNewPage from "./components/ShoppingNewCart";
import RelatedGymProduct from "./components/RelatedProduct/RelatedGymProduct";
import RelatedAllGymProduct from "./components/RelatedProduct/RelatedAllGymProduct";
import RelatedRentProduct from "./components/RelatedProduct/RelatedRentProduct";
import SignUpFormPage from "./components/SignupForm";
import LogInFormPage from "./components/LoginForm";
import SearchFormPage from "./components/SearchForm";
import ShoppingCartPage from "./components/ShoppingCart";
import ProductDiscriptionPage from "./components/ProductDiscription";
import WishListPage1 from "./components/WishList";
import OrderConfirmationPage from "./components/OrderPlaced";
import UserProfile1Page from "./components/UserProfile";
import Policy from "./pages/Policy";
import Privacy from "./pages/Privacy";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import SendOTP from "./pages/ForgotPassword/SendOTP";
import VerifyOTP from "./pages/ForgotPassword/VerifyOTP";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import TermsAndCondetions from "./pages/TermsAndCondetions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import { post } from "./apiIntegrations/fetcher";
import Order from "./pages/Order";
import Landing from "./pages/Landing";
function App() {
  return (
    <div
      className="App"
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        background: "#ccc",
      }}
    >
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Sprentzologo />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/search-results" element={<SearchResltPage />} />
          <Route path="/search/sports" element={<SearchSportsPage />} />
          <Route path="/search/events" element={<SearchEventsPage />} />
          <Route path="/wishlist" element={<WishListPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/orders" element={<OrderPage />} />
          <Route path="/orders/:id" element={<Order />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/discription/:id" element={<ProductSetails />} />
          <Route path="/refundPolicy" element={<Policy />} />
          <Route path="/termsOfUsage" element={<Privacy />} />

          {/* <Route path="/signup-old" element={<SignUpFormPage />} /> */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/send-otp" element={<SendOTP />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/landing" element={<HomePage />} />
          {/* <Route path="/login-old" element={<LogInFormPage />} /> */}
          <Route path="/terms" element={<TermsAndCondetions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home1" element={<HomePage1 />} />
          <Route path="/search1" element={<SearchFormPage />} />
          <Route path="/searchlist" element={<SearchListPage />} />
          <Route path="/searchlist2" element={<SearchListContentPage />} />
          <Route path="/cart1" element={<ShoppingCartPage />} />
          <Route path="/cartnew" element={<CartNewPage />} />
          <Route path="/discription" element={<ProductDiscriptionPage />} />
          <Route
            path="/discription1/:id"
            element={<ProductDiscriptionPage />}
          />
          <Route path="/wishlist1" element={<WishListPage1 />} />
          <Route path="/profile1" element={<UserProfile1Page />} />
          <Route path="/order" element={<OrderConfirmationPage />} />
          <Route path="/sales" element={<SalesPage />} />
          <Route path="/product" element={<RelatedProductPage />} />
          <Route path="/products" element={<RelatedProductPage />} />

          <Route path="/line" element={<RelatedGymProduct />} />
          <Route path="/grid" element={<RelatedAllGymProduct />} />

          <Route path="/rentproduct" element={<RelatedRentProduct />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
console.log("loading -----");

// let dataa = [
//   {
//     name: "evoPOWER 2.17 SNR Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053859/27/fnd/IND/fmt/png/evoPOWER-2.17-SNR-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053859/27/fnd/IND/fmt/png/evoPOWER-2.17-SNR-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053859/27/dt01/fnd/IND/fmt/png/evoPOWER-2.17-SNR-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053859/27/dt02/fnd/IND/fmt/png/evoPOWER-2.17-SNR-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053859/27/dt05/fnd/IND/fmt/png/evoPOWER-2.17-SNR-Bat",
//     ],
//     amount: 13999,
//     buy: 13999,
//     rent: 550,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.3 English Willow Cricket Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053920/07/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053920/07/bv/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053920/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053920/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053920/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053920/07/dt04/fnd/IND/fmt/png/PUMA-Future-20.3-English-Willow-Cricket-Bat",
//     ],
//     amount: 12999,
//     buy: 12999,
//     rent: 450,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.5 English Willow Cricket Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053924/07/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053924/07/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053924/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053924/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053924/07/dt03/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053924/07/dt04/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053924/07/dt05/fnd/IND/fmt/png/PUMA-Future-20.5-English-Willow-Cricket-Bat",
//     ],
//     amount: 9999,
//     buy: 9999,
//     rent: 350,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.6 English Willow Cricket Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053927/07/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053927/07/bv/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053927/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053927/07/dt02/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053927/07/dt02/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053927/07/dt04/fnd/IND/fmt/png/PUMA-Future-20.6-English-Willow-Cricket-Bat",
//     ],
//     amount: 6999,
//     buy: 6999,
//     rent: 250,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "evoSPEED KW 2 Cricket Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053365/25/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053365/25/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053365/25/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053365/25/dt03/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053365/25/dt04/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053365/25/dt05/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053365/25/dt05/fnd/IND/fmt/png/evoSPEED-KW-2-Cricket-Bat",
//     ],
//     amount: 1999,
//     buy: 1999,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "evoPOWER 3.17 Cricket Bat",
//     type: "Cricket Bat",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/053338/23/fnd/IND/fmt/png/evoPOWER-3.17-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053338/23/sv01/fnd/IND/fmt/png/evoPOWER-3.17-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053338/23/sv02/fnd/IND/fmt/png/evoPOWER-3.17-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053338/23/bv01/fnd/IND/fmt/png/evoPOWER-3.17-Cricket-Bat",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/053338/23/dt01/fnd/IND/fmt/png/evoPOWER-3.17-Cricket-Bat",
//     ],
//     amount: 6499,
//     buy: 6499,
//     rent: 200,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "EVO SE Batting pad",
//     type: "Batting Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030653/28/fnd/IND/fmt/png/EVO-SE-Batting-pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030653/28/bv/fnd/IND/fmt/png/EVO-SE-Batting-pad",
//     ],
//     amount: 6499,
//     buy: 6499,
//     rent: 200,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.6 Youth Medium Batting Pad",
//     type: "Batting Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030810/01/fnd/IND/fmt/png/PUMA-Future-20.6-Youth-Medium-Batting-Pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030810/01/bv/fnd/IND/fmt/png/PUMA-Future-20.6-Youth-Medium-Batting-Pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030810/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.6-Youth-Medium-Batting-Pad",
//     ],
//     amount: 2999,
//     buy: 2999,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.2 Cricket Batting Pads",
//     type: "Batting Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030805/01/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Batting-Pads",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030805/01/bv/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Batting-Pads",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030805/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Batting-Pads",
//     ],
//     amount: 4499,
//     buy: 4499,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.6 Medium Batting Pad",
//     type: "Batting Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030809/01/fnd/IND/fmt/png/PUMA-Future-20.6-Medium-Batting-Pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030809/01/bv/fnd/IND/fmt/png/PUMA-Future-20.6-Medium-Batting-Pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030809/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.6-Medium-Batting-Pad",
//     ],
//     amount: 3499,
//     buy: 3499,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.3 Cricket Batting Pads",
//     type: "Batting Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030806/01/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Pads",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030806/01/bv/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Pads",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030806/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Pads",
//     ],
//     amount: 2999,
//     buy: 2999,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "PUMA ONE 20.1 Cricket Wicket Keeper Pads",
//     type: "Keeper Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030803/01/fnd/IND/fmt/png/PUMA-ONE-20.1-Cricket-Wicket-Keeper-Pads",
//     ],
//     amount: 4499,
//     buy: 4499,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "EVO 3 Wicket Keeper pad",
//     type: "Keeper Pads",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/030792/27/fnd/IND/fmt/png/EVO-3-Wicket-Keeper-pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030792/27/bv/fnd/IND/fmt/png/EVO-3-Wicket-Keeper-pad",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/030792/27/dt01/fnd/IND/fmt/png/EVO-3-Wicket-Keeper-pad",
//     ],
//     amount: 1699,
//     buy: 1699,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.5 Bowling Glove",
//     type: "Batting Gloves",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/041711/07/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041711/07/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041711/07/dt02/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Batting-Gloves",
//     ],
//     amount: 1699,
//     buy: 1699,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.6 Cricket Batting Gloves",
//     type: "Batting Gloves",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/041714/09/fnd/IND/fmt/png/PUMA-Future-20.6-Cricket-Batting-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041714/09/bv/fnd/IND/fmt/png/PUMA-Future-20.6-Cricket-Batting-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041714/09/dt01/fnd/IND/fmt/png/PUMA-Future-20.6-Cricket-Batting-Gloves",
//     ],
//     amount: 1099,
//     buy: 1099,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.3 Cricket Wicket Keeping Gloves",
//     type: "Keeper Gloves",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/041717/01/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Wicket-Keeping-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041717/01/bv/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Wicket-Keeping-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041717/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.3-Cricket-Wicket-Keeping-Gloves",
//     ],
//     amount: 1999,
//     buy: 1999,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Future 20.2 Cricket Wicket Keeping Gloves",
//     type: "Keeper Gloves",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/041716/01/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Wicket-Keeping-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041716/01/bv/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Wicket-Keeping-Gloves",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/041716/01/dt01/fnd/IND/fmt/png/PUMA-Future-20.2-Cricket-Wicket-Keeping-Gloves",
//     ],
//     amount: 2999,
//     buy: 2999,
//     rent: 150,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "EVO Moulded Thigh Pad",
//     type: "Thigh Pad",
//     images: ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqEVDuvVnZIf_vRcF8w-TkMlqE6mq_8DrPV-cCZD7kBg&usqp=CAU&ec=48600113"],
//     amount: 599,
//     buy: 599,
//     rent: 100,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
//   {
//     name: "PUMA Bowling 22.1 Unisex Cricket Shoes",
//     type: "Cricket Shoes",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/106669/01/sv01/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/sv02/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/sv03/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/sv04/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/dt01/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/dt02/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106669/01/dt04/fnd/IND/fmt/png/PUMA-Bowling-22.1-Unisex-Cricket-Shoes",
//     ],
//     amount: 13999,
//     buy: 13999,
//     rent: 550,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "PUMA 22 FH Rubber Unisex Cricket Shoes",
//     type: "Cricket Shoes",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/106713/01/sv01/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/bv/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/sv02/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/sv03/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/sv04/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/dt01/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/dt02/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106713/01/dt04/fnd/IND/fmt/png/PUMA-22-FH-Rubber-Unisex-Cricket-Shoes",
//     ],
//     amount: 5999,
//     buy: 5999,
//     rent: 250,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "evoSpeed one8 Youth Shoes",
//     type: "Cricket Shoes",
//     images: [
//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_1050,h_1050/global/106303/03/sv01/fnd/IND/fmt/png/EvoSpeed-One8-Youth-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106303/03/fnd/IND/fmt/png/EvoSpeed-One8-Youth-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106303/03/bv/fnd/IND/fmt/png/EvoSpeed-One8-Youth-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106303/03/sv03/fnd/IND/fmt/png/EvoSpeed-One8-Youth-Cricket-Shoes",

//       "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_600,h_600/global/106303/03/sv04/fnd/IND/fmt/png/EvoSpeed-One8-Youth-Cricket-Shoes",
//     ],
//     amount: 3499,
//     buy: 3499,
//     rent: 200,
//     stock: 0,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "Cricket Bat DSC Scorcher",
//     type: "Cricket Bat",
//     images: [
//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-scorcher-tennis-cricket-bat_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-scorcher-tennis-cricket-bat-1_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-scorcher-tennis-cricket-bat-2_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-scorcher-tennis-cricket-bat-3_2.jpg",
//     ],
//     amount: 1490,
//     buy: 1490,
//     rent: 550,
//     stock: 1,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "Cricket Bat DSC Warrior",
//     type: "Cricket Bat",
//     images: [
//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/d/s/dsc-cricket-warrior-kashmir-willow-bat-2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/d/s/dsc-cricket-warrior-kashmir-willow-bat-1.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/d/s/dsc-cricket-warrior-kashmir-willow-bat-4.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/d/s/dsc-cricket-warrior-kashmir-willow-bat-3.jpg",
//     ],
//     amount: 1294,
//     buy: 1294,
//     rent: 450,
//     stock: 1,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "Cricket Bat DSC Flame",
//     type: "Cricket Bat",
//     images: [
//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-flame-tennis-cricket-bat_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-flame-tennis-cricket-bat-1_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-flame-tennis-cricket-bat-2_2.jpg",

//       "https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/f6804705d3c9b06dccd038949280b6b0/w/i/wildfire-flame-tennis-cricket-bat-3_2.jpg",
//     ],
//     amount: 1520,
//     buy: 1520,
//     rent: 350,
//     stock: 1,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "Cricket Bat - Sprentzo paper weight",
//     type: "Cricket Bat",
//     images: [
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_zkzt5x9kpgLOJ0HPI8JvZzqjbfqBEFgYDp0NmLj2-g&usqp=CAU&ec=48600113",
//     ],
//     amount: 728,
//     buy: 728,
//     rent: 350,
//     stock: 1,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },

//   {
//     name: "Cricket Bat - Sprentzo Burn",
//     type: "Cricket Bat",
//     images: [
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_zkzt5x9kpgLOJ0HPI8JvZzqjbfqBEFgYDp0NmLj2-g&usqp=CAU&ec=48600113",
//     ],
//     amount: 952,
//     buy: 952,
//     rent: 150,
//     stock: 1,
//     sports: ["6470988ef97034c4bebbc6e6"],
//   },
// ];

// console.log(dataa);
// async function load() {
//   dataa.map(async (d) => {
//     console.log(await post("products", d));
//   });
// }
// load()
