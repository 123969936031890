import MianFooter from "../../components/v2/MianFooter";
import MainHeader from "../../components/v2/MainHeader";
import React, { useEffect, useState } from "react";
import { get, post } from "../../apiIntegrations/fetcher";
import { useNavigate } from "react-router-dom";
function ShoppingCartPage() {
  const [items, setItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  let interval = null;
  const navigate = useNavigate();
  useEffect(() => {
    loadOrders();
    interval = setInterval(() => {
      loadOrders();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  async function loadOrders() {
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    console.log(userProfile);
    const res = await get(`orders?user=${userProfile._id}&limit=100`);
    setOrders(res.data);
  }

  return (
    <div
      style={{
        // position: "absolute",

        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
          // background: "#f1f1f1",
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: 20,
            color: "#E15456",
            fontWeight: "600",
          }}
        >
          Orders
        </div>
        <div>
          {orders.map((order, i) => (
            <div
              style={{
                margin: 5,
                padding: 10,
                display: "flex",
                borderBottom: "1px solid #ccc",
              }}
              onClick={(e) => navigate("/orders/" + order._id)}
            >
              <div style={{ flex: 1 }}>
                <div>
                  <div style={{ fontSize: 14, color: "#888" }}>
                    Order ID: {order.razorOrder.id} -{" "}
                    <b style={{ fontSize: 10 }}>
                      {order.razorPayment && (
                        <span
                          style={{
                            textTransform: "capitalize",
                            color:
                              order.razorPayment?.event.split(".")[1] ===
                              "authorized"
                                ? "green"
                                : "red",
                          }}
                        >
                          {" "}
                          {order.razorPayment?.event.split(".")[1] ===
                          "authorized"
                            ? "Success"
                            : "Failed"}
                        </span>
                      )}
                      {!order.razorPayment && (
                        <span style={{ color: "orange" }}>Pending</span>
                      )}
                    </b>
                  </div>
                  <div>{new Date(order.updatedAt).toDateString()}</div>{" "}
                </div>
                {/* {order.razorOrder.id} */}
                <div>
                  {order.items.map((d) => {
                    return (
                      <div
                        style={{
                          borderBottom: "1px solid #eee",
                          paddingLeft: 10,
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            padding: 2,
                            letterSpacing: 0.5,
                            display: "flex",
                          }}
                        >
                          <img
                            src={d.product?.images[0]}
                            alt={d.product?.name}
                            width={30}
                            height={30}
                          />
                          <span style={{ fontWeight: 600 }}>
                            {d.product?.name}(<b>{d.quantity}</b>)
                          </span>
                          {/* {JSON.stringify(d)} */}
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            padding: 2,
                            letterSpacing: 0.5,
                            color: "#666",
                          }}
                        >
                          <div> Venue: {d.venue?.name}</div>
                          <div>
                            Timings:{" "}
                            {d.config?.timeSlot &&
                              new Date(d.config?.timeSlot).toLocaleString()}
                            {/* {d.config?.timeSlot} */}
                          </div>
                          {/* {JSON.stringify(d)} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                ₹{order.razorOrder.amount / 100}{" "}
              </div>
              <div>
                <span style={{ fontSize: 10, color: "#888" }}></span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <MianFooter />
    </div>
  );
}

export default ShoppingCartPage;
