import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";

import { useNavigate } from "react-router-dom";
import bat from "./Bat.jpg";
import events from "./Events-Image.png";
import leftArrow from "../../assets/icons/leftArrow.svg";
import "./index.css";
import SearchBar from "../../components/SearchForm/SearchBar";
const Search = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        // position: "absolute",

        maxWidth: 500,
        margin: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
        }}
      >
        <SearchBar />
        <div style={{ padding: 0, borderTop: "1px SOLID #eee", marginTop: 10 }}>
          <div
            className="search-tile"
            onClick={(e) => navigate("/search/sports")}
          >
            <div>
              <img src={bat} alt={"bat"} style={{ width: 70 }} />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                padding: 10,
              }}
            >
              <b>Sports</b>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={leftArrow} alt={">"} width="10" />
            </div>
          </div>
          <div
            className="search-tile"
            onClick={(e) => navigate("/search/events")}
          >
            <div>
              <img src={events} alt={"events"} style={{ width: 70 }} />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                padding: 10,
              }}
            >
              <b>Sprentzo Community Games</b>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={leftArrow} alt={">"} width="10" />
            </div>
          </div>
        </div>
      </div>
      <MianFooter />
    </div>
  );
};
export default Search;
