import "./index.css";
import arrowLeft from "./image/arrow-left.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyOTP } from "../../../apiIntegrations/auth";
import otpRobot from './image/otp-robot.png';
import verifyOTPArrowButton from './image/verify-otp-button-arrow.png';
const VerifyOTP = () => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleOTPVerification = async (e) => {
    e.preventDefault();
    let res = await verifyOTP({
      userId: localStorage.getItem("phoneNumberForgotPassword"),
      otp
    });
    // console.log(res.token);
    // if (res.error) {
    //   alert("Login Failed Please check the Credentials");
    //   console.log(res);
    // } else {
    //   localStorage.setItem("Authorization", res.token);
    //   navigate("/home");
    // }
    // alert(`Logged in with phone number: ${phone}`);
    if (otp !== "12345") {
      setError("Please provide valid OTP")
      return
    }
    navigate("/reset-password");
    localStorage.setItem("otpForgotPassword", otp)
    setOTP("");
  };

  return (
    <div className="page">
      <div className="sub-page">
        <div className="sub-page-header">
          <img src={arrowLeft} alt="Logo" height="24" width="24" onClick={() => navigate("/send-otp")} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100vw",
            textAlign: "center"
          }}
        >
          <img src={otpRobot} height="200" width="200" alt="robot like structure with eyes as zero" />
          <h1 style={{ margin: "0" }}><i>Enter OTP</i></h1>
          <p style={{ color: "#808080", margin: "12px 20px" }}>Enter the code sent to your registered phone number</p>
          <p className="wrong-phone-number-link" onClick={() => navigate('/send-otp')}>I entered the wrong phone number</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px"

          }}
        >
          <span>
            <input
              id="otp"
              value={otp}
              onChange={handleOTPChange}
              required
              className="otp-input-user-id"
              placeholder="OTP"
            />
          </span>

          <button
            className="verify-otp-button"
            onClick={handleOTPVerification}
          >
            <img src={verifyOTPArrowButton} alt="arrow, on the button to verify otp" className="verify-otp-button-arrow" width="30" height="30"></img>
          </button>

        </div>
        <p className="error-otp">{error}</p>
        <div style={{ flex: 1 }}></div>
      </div >
    </div >
  );
};

export default VerifyOTP;
