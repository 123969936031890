import HomePageHeader from '../HomePage/HomeHeader/index';
import HeaderSearchBar from '../SearchForm/SearchBar/index';
import FooterPage from '../Footer';
import SearchListContent from './SearchListContent';


function SearchListContentPage () {
    return (
        <div className="search-bar-container">
            <HomePageHeader />
            <HeaderSearchBar />
            <SearchListContent />
            <FooterPage />
        </div>
    );
}

export default SearchListContentPage;