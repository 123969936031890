import "./index.css";
import arrowLeft from "./image/arrow-left.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../apiIntegrations/auth";
import batsmanGreen from './image/batsman-green.png';
const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState({ new: "", confirm: "" });
  const [error, setError] = useState({ new: "", confirm: "" });

  const handlePasswordChange = (passwordIteration, e) => {
    console.log({ ...password, [passwordIteration]: e.target.value }, password.new !== password.confirm)
    setPassword((password) => { return { ...password, [passwordIteration]: e.target.value } });
  };

  useEffect(() => {
    if (password.new === password.confirm) {
      setError((error) => { return { new: error.new, confirm: "" } })
      return;
    }
  }, [password])

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (password.new !== password.confirm) {
      setError((error) => { return { new: error.new, confirm: "Passwords don't match!" } })
      return;
    }
    let res = await resetPassword({
      userId: localStorage.getItem("phoneNumberForgotPassword"),
      otp: localStorage.getItem("otpForgotPassword"),
      password: password.new
    });
    console.log(res);
    navigate("/login");
    // console.log(res.token);
    // if (res.error) {
    //   alert("Login Failed Please check the Credentials");
    //   console.log(res);
    // } else {
    //   localStorage.setItem("Authorization", res.token);
    //   navigate("/home");
    // }


    // alert(`Logged in with password number: ${password}`);
    setPassword("");
  };


  return (
    <div className="page">
      <div className="sub-page">
        <div className="sub-page-header">
          <img src={arrowLeft} alt="Logo" height="24" width="24" onClick={() => navigate("/verify-otp")} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100vw",
            textAlign: "center"
          }}
        >
          <img src={batsmanGreen} height="200" width="200" alt="outline of batsman, in green color" />
          <h1 style={{ margin: "0" }}><i>Reset Password</i></h1>
          <p style={{ color: "#808080", margin: "12px 20px" }}>You're almost there, one step away from getting back on</p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50
          }}
        >
          <span>
            <input
              type="password"
              id="newPassword"
              value={password.new}
              onChange={(e) => handlePasswordChange('new', e)}
              required
              className="password-input-box"
              placeholder="New Password"
            />
          </span>
          <p className="error-password">{error.new}</p>
          <span>
            <input
              type="password"
              id="newPassword"
              value={password.confirm}
              onChange={(e) => handlePasswordChange('confirm', e)}
              required
              className="password-input-box"
              placeholder="Confirm Password"
            />
          </span>
          <p className="error-password">{error.confirm}</p>

          <button
            className="password-reset-button"
            style={{ color: "#fff", fontWeight: 500, letterSpacing: 1 }}
            onClick={handlePasswordReset}
          >
            Reset Password
          </button>

        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  );
};

export default ResetPassword;
