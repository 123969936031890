import React, { useState } from 'react';
import './index.css'


function ProductInfo() {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    }

    return (
        <div className="tabs">
            <ul className="tab-buttons">
                <li className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>Discription</li>
                <li className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}>Additional info</li>
            </ul>
            <div className="tab-content">
                {activeTab === 1 &&
                    <p>
                        <ul>
                            <li>The football offers amazing grip which translates into a playing great experience.</li>
                            <li> Hard Ground without Grass, Wet & Grassy Ground, Artificail Turf.</li>
                            <li>Suitable For: All Conditions | Ideal For: Training/Match.</li>
                            <li>Best Football Sport Toys The Ultimate Soccer Game, with
                                Multi Lighting Feature -Magic Hover Football Toy Indoor Play
                                Game Best Toy for Kid- Multi Color..</li>
                            <li>Included Components : 1 Football</li>
                        </ul>

                    </p>
                }
                {activeTab === 2 && <p><ul>
                    <li>The football offers amazing grip which translates into a playing great experience.</li>
                    <li> Hard Ground without Grass, Wet & Grassy Ground, Artificail Turf.</li>
                    <li>Suitable For: All Conditions | Ideal For: Training/Match.</li>
                </ul></p>}

            </div>
        </div>
    );
}

export default ProductInfo;
