import HomePageHeader from '../HomePage/HomeHeader/index';
import HeaderSearchBar from '../SearchForm/SearchBar/index';
import SearchList from './SearchList/index';
import FooterPage from '../Footer';


function SearchListPage () {
    return (
        <div className="search-bar-container">
            <HomePageHeader />
            <HeaderSearchBar />
            <SearchList />
            <FooterPage />
        </div>
    );
}

export default SearchListPage;