import { useState } from 'react';
import FooterPage from '../Footer';
import HomePageHeader from '../HomePage/HomeHeader/index';
import SearchBar from './SearchBar/index';
import SearchProduct from './SearchProductCard/index';


function SearchFormPage() {

    const [SearchProductItems] = useState([
        {
            title: 'Most popular ',
            image: process.env.PUBLIC_URL + '/Assests/searchproduct/ftball.png',
            background: "#4BAF61"
        },
        {
            title: 'Sports',
            image: process.env.PUBLIC_URL + '/Assests/searchproduct/crickit.png',
            background: "#368EF7"
        },
        {
            title: 'Equipment or accessory',
            image: process.env.PUBLIC_URL + '/Assests/searchproduct/gymkit.png',
            background: "#EB57A1"
        },
        {
            title: 'Brands',
            image: process.env.PUBLIC_URL + '/Assests/searchproduct/brand.png',
            background: "#FFDE68"
        },
    ])

    return (
        <div className="search-bar-container">
            <HomePageHeader />
            <SearchBar />
            <SearchProduct SearchProductItems={SearchProductItems} />
            <FooterPage />
        </div>
    );
}

export default SearchFormPage;