import CartHeader from './CartHeader/index';
import CartSelectItems from './CartSelectItems/index';
import CartPayment from './CartPayment/index';
import FooterPage from '../Footer/index';
import React, { useState } from 'react';

function ShoppingCartPage() {
    const [cartItems, setCartItems] = useState([
        {
            // productID:"asd",
            id: 1,
            image: process.env.PUBLIC_URL + '/Assests/cartselectitems/gym.png',
            title: 'Gym home workout',
            price: 120,
            purchaseType: "Rent",
            quantity: 1,
            delevaryCharges: 50,
            decreaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/minus.png',
            increaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/plus.png',
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + '/Assests/cartselectitems/ftball.png',
            title: 'Football',
            price: 1200,
            purchaseType: "Rent",
            quantity: 1,
            delevaryCharges: 50,
            decreaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/minus.png',
            increaseButton: process.env.PUBLIC_URL + '/Assests/cartselectitems/plus.png',
        },

    ]);

    const handleDelete = (i) => {
        setCartItems(cartItems.filter(item => item.i !== i));
    }

    const increaseItems = (i) => {
        let _tmpItems = [...cartItems]
        _tmpItems[i].quantity += 1
        setCartItems(_tmpItems);
    };

    const decreaseItems = (i) => {
        let _tmpItems = [...cartItems]
        if (_tmpItems[i].quantity > 1)
            _tmpItems[i].quantity -= 1
        setCartItems(_tmpItems);
    };

    return (
        <div className="cart-page-container">
            <CartHeader />
            <CartSelectItems cartItems={cartItems} increaseItems={increaseItems} decreaseItems={decreaseItems} handleDelete={handleDelete} />
            <CartPayment cartItems={cartItems} />
            <FooterPage />
        </div>
    );
}

export default ShoppingCartPage;