import c1 from "./Flexiblity.png";
import c2 from "./Only-Top-Quality.png";
import c3 from "./Pay-As-You-Play.png";
import c4 from "./Never-Be-Changed.png";
import c5 from "./Browse.png";
import c6 from "./Easy-Plug-Play-At-Venues.png";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
};

const HomeBranging = ({ title, items = [] }) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 50 }}>
      <div
        style={{
          //   border: "1px solid red",
          paddingLeft: 20,
          paddingBottom: 15,
          letterSpacing: 0.7,
          color: "#333",
          fontSize: 18,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {"Renting With Sprentzo"}
      </div>
      <div
        style={{
          // width: "calc( 100% - 20px)",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TrendingCard
          title={"Flexiblity and choice"}
          bgColor="#e35556"
          image={c1}
        />
        <TrendingCard
          title={"Only top quality products"}
          bgColor="#529cd5"
          image={c2}
        />
        <TrendingCard title={"Pay as you play"} bgColor="#7968aa" image={c3} />
      </div>
      <div
        style={{
          // width: "calc( 100% - 20px)",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TrendingCard
          title={"Never be chained to one activity"}
          bgColor="#e35556"
          image={c4}
        />
        <TrendingCard
          title={"Browse across locations"}
          bgColor="#529cd5"
          image={c5}
        />
        <TrendingCard
          title={"Easy plug play at the venues"}
          bgColor="#7968aa"
          image={c6}
        />
      </div>
    </div>
  );
};

export default HomeBranging;

const TrendingCard = ({ title, details, image, bgColor = "#fff" }) => {
  return (
    <div
      style={{
        width: 145,
        height: 165,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: "rgb(244 245 246)",
        margin: 5,
      }}
    >
      <img src={image} style={{ height: 85, margin: 5 }} />
      <div
        style={{
          fontSize: 15,
          fontWeight: 400,
          textAlign: "center",
          height: 40,
          display: "flex",
          alignItems: "center",
          paddingLeft: 16,
          paddingRight: 16,
          color: "#999",
        }}
      >
        {title}
      </div>
    </div>
  );
};
