import FooterPage from "../Footer";
import UserLocation from "./UserLocation";
import UserPayment from "./UserPayment";
import UserProfileInfo from "./UserProfileInfo";
import UserSetting from "./UserQuery";


function UserProfilePage () {
    return (
        <div className="profile-section">
            <UserProfileInfo />
            <UserLocation />
            <UserPayment />
            <UserSetting />
            <FooterPage />
        </div>
    )

}

export default UserProfilePage;