import React from "react";
import './index.css';

let SearchListData = [
    {
        title: 'Badminton'
    },
    {
        title: 'Baseball'
    },
    {
        title: 'Cricket'
    },
    {
        title: 'Snooker'
    },
]

function SearchListContent() {
    return (
        <>
            
            <div className="search-list-content-container">
                {SearchListData.map((d, i) => (
                    <div className="search-list-content-block">
                        <div className="search-list-content-title">{d.title}</div>
                    </div>

                ))} 
            </div>
        </>
    );
}

export default SearchListContent;