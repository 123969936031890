import React from "react";
import "./index.css";
import logo from "../UserProfileInfo/images/sprentzo.png";
import profile from "../UserProfileInfo/images/profile.png";

function UserProfileInfo() {
  return (
    <>
      <div className="profile-section-logo">
        <img src={logo} alt="" />
      </div>
      <div className="profile-section-container">
        <div className="profile-section-image">
          <img
            src={
              "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZmlsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
            }
            alt=""
            width={75}
            height={57}
          />
        </div>
        <div className="profile-section-details">
          <div className="profile-section-name">Ganesh Hosamani</div>
          <div className="profile-section-phone-number">(+91) 9980594085</div>
        </div>
      </div>
      <div className="profile-subscribe-section">
        <p className="profile-section-para">
          Become a subscriber and access unlimited sports equipment and a host
          of other benefits from your neighborhood.
        </p>
        <div className="profile-button-container">
          <button className="profile-create-button">Create Account</button>
          <button className="profile-subscribe-button">Subscribe Now</button>
        </div>
      </div>
    </>
  );
}

export default UserProfileInfo;
