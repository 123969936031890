import React from "react";
import './index.css';


let NewRentGymProductData = [
    {
        image: process.env.PUBLIC_URL + '/Assests/RelatedAllProduct/tytax.png',
        title: 'TYTAX Machine',
        buy: 'Buy',
        amount : '28000',
        rent: 'Rent ₹1200',
    },
    {
        image: process.env.PUBLIC_URL + '/Assests/RelatedAllProduct/dumbelsset.png',
        title: 'Dumbbells sets',
        buy: 'Buy',
        amount : '40000',
        rent: 'Rent ₹800',
    },


]
function RelatedRentGymProduct() {
    return (
        <>
            <div className="related-rent-gym-product-container">
                {NewRentGymProductData.map((d, i) => (
                    <div className="related-rent-gym-product-block">
                        <div className="related-rent-gym-product-image">
                            <img src={d.image} alt="" />
                        </div>
                        <div className="related-rent-gym-product-title">{d.title}</div>
                        <div className="related-rent-gym-product-price">
                            <div className="related-rent-gym-price1">
                                <div className="related-rent-gym-product-buy">{d.buy} </div>
                                <div className="related-rent-gym-product-buy-amount">{d.amount}</div>
                            </div>
                            <div className="related-rent-gym-price2">{d.rent}
                            </div>
                        </div>
                        <div className="related-rent-product-button">
                            <button className="product-rent-discover-button">Discover</button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default RelatedRentGymProduct;