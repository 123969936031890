import React from "react";
import './index.css';
import maplogo from '../UserPayment/images/card.png'
import map from '../UserPayment/images/card1.png'


function UserPayment (){
    return(
        <div className="profile-payment-container">
            <div className="profile-payment-block1">
                <div className="profile-payment-image">
                    <img src={maplogo} alt='' />
                </div>
                <div className="profile-payment-address">Credit card</div>
            </div>
            <div className="profile-payment-line"></div>
            <div className="profile-payment-block2">
            <div className="profile-payment-image">
                    <img src={map} alt='' />
                </div>
                <div className="profile-payment-address">**** **** **** 9876</div>
            </div>
        </div>

    );
}

export default UserPayment;