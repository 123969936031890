import addedToCart from './images/added_to_cart.png'
import loginFailed from './images/login_failed.png'
import successfulPurchase from './images/successfully_purchased.png'
import registrationFailed from './images/registration_failed.png'
import addedToWishlist from './images/wishlist.png'
const modalContent = {

    "addedToCart": {
        "message": "Added To Cart",
        "description": "This product is now in your cart. Your're a step closer to scoring!",
        "backgroundColor": "#37ab65",
        "color": "#ffffff",
        "icon": addedToCart
    },
    "loginFailed": {
        "message": "Login Failed",
        "description": "Oops, looks like you missed the goal. Keep your head up and try again!",
        "backgroundColor": "#f05025",
        "color": "#ffffff",
        "icon": loginFailed
    },
    "successfulPurchase": {
        "message": "Successful",
        "description": "You've successfully rented the product. Go on, ace it now! And let us know how it goes!",
        "backgroundColor": "#fad93a",
        "color": "#7869ac",
        "icon": successfulPurchase
    },
    "registrationFailed": {
        "message": "Registration Failed",
        "description": "Seems like you're not quite in the game yet. Don't worry, we'll help you get back on the field!",
        "backgroundColor": "#3762ae",
        "color": "#fad93a",
        "icon": registrationFailed
    },
    "addedToWishlist": {
        "message": "Wishlist",
        "description": "This product is now on your Wishlist. Don't let it slip away like a catch...",
        "backgroundColor": "#7869ac",
        "color": "#fad93a",
        "icon": addedToWishlist
    }
}

export default modalContent