import React from 'react';
import './index.css';
// import deleteicon from './images/delete.png';

function CartSelectItems({ cartItems, decreaseItems, increaseItems, handleDelete }) {


    return (
        <div className="cart-select-item-container">
            {cartItems.map((d, i) => (
                <>
                    <div className='p-container' key={i}>
                        <div key={"item" + i} className="cart-select-item-block" >
                            <div className="cart-select-item-image">
                                <img src={d.image} alt="" width={60} height={60} />
                            </div>
                            <div className="cart-select-items"  >
                                <div className="cart-select-item-title">{d.title}</div>
                                <div className="cart-select-item-price">{d.purchaseType} ₹{d.price}</div>
                            </div>
                            <div className="cart-select-item-no">
                                <button className="cart-select-decrease" onClick={e => decreaseItems(i)}>
                                    <img src={d.decreaseButton} alt="" width={20} height={20} />
                                </button>
                                <p className="cart-select-items-value">{d.quantity}</p>
                                <button className="cart-select-increase" onClick={e => increaseItems(i)}>
                                    <img src={d.increaseButton} alt="" width={20} height={20} />
                                </button>
                            </div>
                        </div>
                        {/* <div className='delete'>
                            <img src={deleteicon} alt="" className='delete-icon' onClick={() => handleDelete("item" + i)} />
                        </div> */}
                    </div>
                </>

            ))}

        </div>
    );
}

export default CartSelectItems;