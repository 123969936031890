import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../UserProfileInfo/images/sprentzo.png";
import profile from "./images/profile_placeholder.png";

import { Link, useNavigate } from "react-router-dom";
function UserProfileInfo() {
  const navigate = useNavigate();
  const [user, setUser] = useState({})
  useEffect(() => {
    const userProfile = localStorage.getItem("userProfile")
    console.log("userProfile")
    const uf = JSON.parse(userProfile)
    console.log(uf)
    if (!uf) {
      alert("User session Expired! Login Again")
      // localStorage.clear()
      localStorage.removeItem('Authorization')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('phoneNumberForgotPassword')
      localStorage.removeItem('otpForgotPassword')
      localStorage.removeItem('filter')
      navigate('/home');
    }
    setUser(uf)
  }, [])
  return (
    <>
      {/* <div className="profile-section-logo">
        <img src={logo} alt="" /> 
      </div> */}
      <div className="profile-section-container">
        <div className="profile-section-image">
          <img
            src=
            {profile}

            alt=""
            width={57}
            height={57}
          />
        </div>
        <div className="profile-section-details">
          <div className="profile-section-name">{user.firstName} {user.lastName}</div>
          <div className="profile-section-phone-number">(+91){user.phone}</div>
        </div>
      </div>
      <div className="profile-subscribe-section">
        {/* <p className="profile-section-para">
          Become a subscriber and access unlimited sports equipment and a host
          of other benefits from your neighborhood.
        </p> */}
        {/* <div className="profile-button-container">
          <button className="profile-create-button">Create Account</button>
          <button className="profile-subscribe-button">Subscribe Now</button>
        </div> */}
      </div>
    </>
  );
}

export default UserProfileInfo;
