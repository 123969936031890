import React from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";

function CartPayment({ cart, cartItems, items, displayRazorpay }) {
  const price = items
    .map((d) => {
      return d.product.type === 'Event' ? d.config.price :
        (d.product.rent + ((((d.config?.duration - 90) / 30) * 25) / 100) * d.product.rent) * d.quantity
    })
    .reduce((a, b) => a + b, 0);
  const navigate = useNavigate();

  return (
    <div className="cart-payment-title-container">
      <div className="cart-payment-title">Payment summary</div>

      <div className="cart-payment-title-block">
        <div className="cart-payment-items">
          <div className="cart-payment-item">{items.length} items</div>
          <div className="cart-payment-price">₹{price}</div>
        </div>
        <div className="cart-payment-items">
          {/* <div className="cart-payment-item">Delivery Charge</div>
                        <div className="cart-payment-price">{delevaryCharges}</div> */}
        </div>
        {/* <div className="cart-payment-total">
          <div className="cart-payment-item">GST (18%):</div>
          <div className="cart-payment-price">₹{18 / 100 * price}</div>
        </div> */}
        <div className="cart-payment-total">
          <div className="cart-payment-item">Total</div>
          {/* <div className="cart-payment-price">₹{price + (18 / 100 * price)}</div> */}
          <div className="cart-payment-price">₹{price}</div>
        </div>
        <div className="cart-payment-button">
          <button
            type="checkout"
            disabled={!(items.length >= 1)}
            style={{ background: items.length >= 1 ? "#7868ab" : "#ccc" }}
            onClick={(e) => {
              if (items.length < 1) {
                alert('no items in cart')
              }
              if (items.length >= 1) {
                displayRazorpay(cart, price, (o) => {
                  navigate("/orders/" + o._id);
                });
                // navigate('/orders');
              }
            }}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartPayment;
