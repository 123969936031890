import banner from "../../../assets/banner1.png";
// import DG1 from "../../../pages/Home/banners/DG1.png";
// import DG2 from "../../../pages/Home/banners/DG2.png";
import AD1 from "../../../pages/Home/banners/1.png";
import AD2 from "../../../pages/Home/banners/2.png";
import AD3 from "../../../pages/Home/banners/3.png";
import AD4 from "../../../pages/Home/banners/4.png";
// import "../HomeMainBanner/index.css";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const HomeMainBanner = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        // border: "1px solid red",
        width: "calc( 100% - 20px)",
        margin: 10,
      }}
    >
      <Slider {...settings}>
        {/* <div style={{ border: "1px solid red" }}>
              <h3 style={{ border: "1px solid red" }}>1</h3>
            </div> */}
        {/* <div style={{ marginBottom: "2px" }}>
          <div
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
            onClick={(e) => {
              localStorage.setItem("filter", "");
              navigate(`/discription/${process.env.REACT_APP_EVENT_MONGO_ID}`)
            }}
          >
            <img src={DG1} alt={"banner"} width={"100%"} />
          </div>
        </div> */}
        {/* <div style={{ marginBottom: "2px" }}>
          <div
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
            onClick={(e) => {
              localStorage.setItem("filter", "");
              navigate(`/discription/${process.env.REACT_APP_EVENT_MONGO_ID}`)
            }}
          >
            <img src={DG2} alt={"banner"} width={"100%"} />
          </div>
        </div> */}
        <div style={{ marginBottom: "2px" }}>
          <div
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
            onClick={(e) => {
              localStorage.setItem("filter", "Football");
              navigate("/search-results/")
            }}
          >
            <img src={AD1} alt={"banner"} width={"100%"} />
          </div>
        </div>
        <div>
          <div
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
            onClick={(e) => {
              localStorage.setItem("filter", "Football");
              navigate("/search-results/")
            }}
          >
            <img src={AD2} alt={"banner"} width={"100%"} />
          </div>
        </div>
        <div>
          <div
            onClick={(e) => {
              localStorage.setItem("filter", "Cricket");
              navigate("/search-results/")
            }}
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
          >
            <img src={AD3} alt={"banner"} width={"100%"} />
          </div>
        </div>
        <div>
          <div
            onClick={(e) => {
              localStorage.setItem("filter", "Cricket");
              navigate("/search-results/")
            }}
            style={
              {
                // border: "1px solid #ccc",
                // paddingTop: 20,
                // paddingLeft: 10,
                // paddingBottom: 0,
                // paddingRight: 0,
              }
            }
          >
            <img src={AD4} alt={"banner"} width={"100%"} />
          </div>
        </div>

      </Slider>
    </div>
  );
};
export default HomeMainBanner;
