import React, { useState } from "react";
import FooterPage from "../Footer";
import RelatedTitleOption from "./RelatedOption";
import SportsTypeOption from "./RelatedSportsOptions";
import RelatedRentGymProduct from "./RelatedRentGymProduct";
import RelatedRentProduct from "./RelatedRentProduct";
import RelatedBuyGymProduct from "./RelatedBuyGymProduct";
import RelatedBuyProduct from "./RelatedBuyProduct";
import RelatedGymProduct from "./RelatedGymProduct";
import RelatedAllGymProduct from "./RelatedAllGymProduct";
import line from "./RelatedOption/images/line.svg";
import grid from "./RelatedOption/images/grid.svg";

let NewAllGymProductData = [
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedAllProduct/tytax.png",
    title: "The Cube Club Adjustable Stepper Bench",
    subtitle:
      "Sports equipment Sports equipment Sports equipm Sports equipment",
    buy: "Buy",
    amount: "28000",
    rent: "Rent ₹1200",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedAllProduct/dumbelset.png",
    title: "Dembals",
    subtitle:
      "Sports equipment Sports equipment Sports equipm Sports equipment",
    buy: "Buy",
    amount: "28000",
    rent: "Rent ₹1200",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedAllProduct/gym.png",
    title: "GAMMA FITNESS FTS-101",
    subtitle:
      "Sports equipment Sports equipment Sports equipm Sports equipment",
    buy: "Buy",
    amount: "28000",
    rent: "Rent ₹1200",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedAllProduct/homgym.png",
    title: "Slovic Adjustable Gym Bench for Home Workout",
    subtitle:
      "Sports equipment Sports equipment Sports equipm Sports equipment",
    buy: "Buy",
    amount: "28000",
    rent: "Rent ₹1200",
  },
];

function RelatedProductPage() {
  const [showComponent, setShowComponent] = useState(true);
  const [gridComponent, setGridComponent] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const list = () => {
    setShowComponent(true);
    setGridComponent(false);
  };
  const grid1 = () => {
    setGridComponent(true);
    setShowComponent(false);
  };
  return (
    <div className="related-product">
      {/* <SportsTypeOption /> */}
      <Filter
        list={list}
        grid1={grid1}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <div
        style={{
          flex: 1,
          marginBottom: 62,
          background: "#F5F5F5",
          overflow: "scroll",
        }}
      >
        {showComponent && (
          <RelatedGymProduct NewAllGymProductData={NewAllGymProductData} />
        )}
        {gridComponent && (
          <RelatedAllGymProduct NewAllGymProductData={NewAllGymProductData} />
        )}
      </div>
      {/* <RelatedTitleOption /> */}
      {/* <RelatedGymProduct /> */}
      {/* <RelatedAllGymProduct /> */}

      {/* <RelatedRentProduct /> */}
      {/* <RelatedRentGymProduct /> */}

      {/* <RelatedBuyProduct /> */}
      {/* <RelatedBuyGymProduct /> */}
      <FooterPage />
    </div>
  );
}

export default RelatedProductPage;
let SportsCardsData = [
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedSportsItem/ftball.png",
    title: "Sports Equipment",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedSportsItem/gym.png",
    title: "Gym Equipment",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedSportsItem/cricket.png",
    title: "Cricket",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedSportsItem/dumbels.png",
    title: "Dumbbels",
  },
  {
    image: process.env.PUBLIC_URL + "/Assests/RelatedSportsItem/yogab.png",
    title: "Yoga Ball",
  },
];
const Filter = ({ grid1, list, selectedFilter, setSelectedFilter }) => {
  return (
    <div style={{ padding: 10 }}>
      <div className="filter-content-container" style={{ display: "flex" }}>
        {SportsCardsData.map((d, i) => (
          <div
            style={{
              textAlign: "center",
              paddingTop: 28,
            }}
          >
            <div
              style={{
                width: 60,
                height: 60,
                margin: 12,
                marginBottom: 4,
                background: "#E8EAEC",
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={d.image} alt="" width={30} height={30} />
            </div>
            <div
              style={{
                fontSize: 8,
                fontWeight: 500,
                color: "#45496A",
                maxWidth: 45,
                textAlign: "center",

                margin: "auto",
              }}
            >
              {" "}
              {d.title}
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 30, display: "flex" }}>
        <div style={{ flex: 1, display: "flex" }}>
          <div
            style={{
              margin: 10,
              fontWeight: 600,
              fontSize: 20,
              color: "#45496A",
              paddingBottom: 4,
              borderBottom:
                selectedFilter === "all"
                  ? "2px solid #E15456"
                  : "2px solid #fff",
            }}
            onClick={(e) => setSelectedFilter("all")}
          >
            All
          </div>
          <div
            style={{
              margin: 10,
              fontWeight: 600,
              fontSize: 20,
              color: "#45496A",
              paddingBottom: 4,
              borderBottom:
                selectedFilter === "rent"
                  ? "2px solid #E15456"
                  : "2px solid #fff",
            }}
            onClick={(e) => setSelectedFilter("rent")}
          >
            Rent
          </div>
          <div
            style={{
              margin: 10,
              fontWeight: 600,
              fontSize: 20,
              color: "#45496A",
              paddingBottom: 4,
              borderBottom:
                selectedFilter === "buy"
                  ? "2px solid #E15456"
                  : "2px solid #fff",
            }}
            onClick={(e) => setSelectedFilter("buy")}
          >
            To Buy
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <img
            src={line}
            alt=""
            onClick={(e) => {}}
            style={{ margin: 10, height: 25, width: 25 }}
            onClick={list}
          />
          <img
            src={grid}
            alt=""
            onClick={(e) => {}}
            style={{ margin: 10, height: 25, width: 25 }}
            onClick={grid1}
          />
        </div>
      </div>
    </div>
  );
};
