import CustomDropdown from "./DiscriptionDropdown";
import ProductDeliveryestimate from "./ProductDeliveryDetails";
import ProductDescriptionContent from "./ProductDescriptionContent";
import ProductImageGallery from "./ProductImageGallery";
import ProductInfo from "./ProductInfo";
import ProductStockDetails from "./ProductStockDetails";
import RelatedProductItem from "./RelatedProductItems";
import { useParams } from "react-router-dom";

function ProductDiscriptionPage() {
  let { id } = useParams();
  return (
    <div className="product-discription" style={{ paddingBottom: 165 }}>
      <ProductImageGallery />
      <ProductDescriptionContent id={id || "Football"} />
      <CustomDropdown />
      <ProductDeliveryestimate />
      <ProductStockDetails />
      <ProductInfo />
      <RelatedProductItem />
      <div
        style={{
          background: "#fff",
          position: "fixed",

          padding: 10,
          zIndex: 999,
          bottom: 0,
          height: 65,
          width: "calc(100% - 20px)",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            border: "1px solid #E15456",
            margin: 5,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 18,
            fontWeight: 700,
            height: 60,
          }}
        >
          Add to wishlist
        </div>
        <div
          style={{
            flex: 1,
            border: "1px solid #E15456",
            background: "#E15456",
            color: "#fff",
            margin: 5,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 18,
            fontWeight: 700,
            height: 60,
          }}
        >
          Add to cart
        </div>
      </div>
    </div>
  );
}

export default ProductDiscriptionPage;
