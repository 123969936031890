import React from "react";
import './index.css';
import maplogo from '../UserLocation/images/map1.png'
import map from '../UserLocation/images/map2.png'


function UserLocation (){
    return(
        <div className="profile-location-container">
            <div className="profile-location-block1">
                <div className="profile-location-image">
                    <img src={maplogo} alt='' />
                </div>
                <div className="profile-location-address">India</div>
            </div>
            <div className="profile-location-line"></div>
            <div className="profile-location-block2">
            <div className="profile-location-image">
                    <img src={map} alt='' />
                </div>
                <div className="profile-location-address">4th cross 2nd main HSR Layout Bangalore karnataka</div>
            </div>
        </div>

    );
}

export default UserLocation;