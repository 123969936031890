import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";
import banner from "../../assets/banner1.png";
import AD4 from "./banners/AD4.png";
import CategoriesList from "../../components/v2/CategoriesList";
import HomeMainBanner from "../../components/v2/HomeMainBanner";

import NewArrivalProducts from "../../components/v2/NewArrivalProducts";
import HomePageProductList from "../../components/v2/HomePageProductList";
import cricket from "../../assets/sports/cricket.png";
import football from "../../assets/sports/football.png";
import badminton from "../../assets/sports/badminton.jpg";
import HomeTrendingNow from "../../components/v2/HomeTrendingNow";
import HomeBranging from "../../components/v2/HomeBranging";
import { useEffect, useState } from "react";
import { get } from "../../apiIntegrations/fetcher.js";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const [products, setProducts] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("initial load");
    checkForUserSession();
    console.log("checkForUserSession");
    loadProducts();
    console.log("loaded Products done");
  }, []);
  async function loadProducts() {
    const res = await get("products?limit=5&homePageCarouselProduct=1");
    console.log("loaded Products", res);
    setProducts(res.data);
  }
  async function checkForUserSession() {
    setShowLoading(true);
    const token = localStorage.getItem("Authorization");
    // if (!token) return navigate("/landing");
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDVmM2JlZjVlMDhjMjA2MWU1ZmU2OTgiLCJpYXQiOjE2ODQyNDQ4OTZ9.N8gkmWdsVjsF5uaXQyDFbTu5wQv9W9SqRWjXxALSiZM"
    try {
      if (token) {
        const data = await get("/profile", {}, { Authorization: token });
        console.log("userprofile =====> ", data);
        localStorage.setItem("userProfile", JSON.stringify(data));
      }
      setShowLoading(false);
    } catch (e) {
      console.log(45, e);
      // navigate("/login");
      setShowLoading(false);
    }
  }
  if (showLoading)
    return (
      <div
        style={{
          // position: "absolute",
          height: "100%",
          width: "100%",
          maxWidth: 500,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          background: "#fff",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center", fontSize: 22 }}>
          Loading please wait...
        </div>
      </div>
    );
  return (
    <div
      style={{
        // position: "absolute",
        height: "100%",
        width: "100%",
        maxWidth: 500,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainHeader />

      <div
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            padding: 10,
            textAlign: "center",
            background: "#7767ac",
            color: "#fff",
            fontWeight: 700,
            letterSpacing: 1,
          }}
        >
          Bengaluru we're here!
        </div>
        <HomeMainBanner />
        <CategoriesList />
        <HomePageProductList products={products} />
        <HomeTrendingNow />
        <HomeBranging />
        <div style={{ marginTop: 10 }}>
          <a
            style={{ color: "#222", textDecoration: "none" }}
            href={`mailto:${"hello@sprentzo.com"}?subject=${encodeURIComponent("Ask US How") || ""
              }&body=${encodeURIComponent("") || ""}`}
          >
            <img
              src={AD4}
              width={"100%"}
              style={{ maxWidth: 500, margin: "auto", minWidth: 320 }}
              alt="banner"
            />
          </a>
        </div>
      </div>
      <MianFooter />
    </div>
  );
};
export default Home;


/* <NewArrivalProducts />
      <HomePageProductList
        title={"Football Related Products"}
        items={[
          {
            title: "Football",
            icon: football,
          },
          {
            title: "Cricket",
            icon: cricket,
          },
          {
            title: "Badminton",
            icon: badminton,
          },
          {
            title: "Football",
            icon: football,
          },
        ]}
      />
      <HomePageProductList
        title={"Cricket Related Products"}
        items={[
          {
            title: "Football",
            icon: football,
          },
          {
            title: "Cricket",
            icon: cricket,
          },
          {
            title: "Badminton",
            icon: badminton,
          },
          {
            title: "Football",
            icon: football,
          },
        ]}
      />
      <HomePageProductList
        title={"Badminton Related Products"}
        items={[
          {
            title: "Football",
            icon: football,
          },
          {
            title: "Cricket",
            icon: cricket,
          },
          {
            title: "Badminton",
            icon: badminton,
          },
          {
            title: "Football",
            icon: football,
          },
        ]}
      /> */

