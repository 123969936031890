import React from "react";
import "./index.css";
import searchicon from "../../../assets/icons/search.svg";
import removesearch from "../SearchBar/history.png";

function SearchBar() {
  return (
    <div className="search-bar">
      <div className="search-bar-search-icon">
        <img src={searchicon} alt="" width={25} height={25} />
      </div>
      <input
        type="text"
        className="search"
        placeholder="Search..."
        style={{ border: "none", marginLeft: 0 }}
      />
      <div className="search-bar-history-icon">
        {/* <img src={removesearch} alt="" width={30} height={30} /> */}
      </div>
    </div>
  );
}

export default SearchBar;
