const FooterLinkTile = ({ title, icon, link, onClick, badge }) => {
  return (
    <div
      onClick={onClick}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          //   border: "1px solid red",
          width: 70,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ margin: 7 }}>
          <img src={icon} alt={title} width={20} />
        </div>
        <div style={{ fontSize: 11, color: "#333", display: "flex" }}>
          <div>{title}</div>

          {!!badge && (
            <div style={{ width: 0 }}>
              <div
                style={{
                  position: "relative",
                  top: -36,
                  left: 6,
                  background: "#e15456",
                  color: "#fff",
                  width: 15,
                  borderRadius: 10,
                  height: 15,
                  textAlign: "center"
                }}
              >
                {badge}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FooterLinkTile;
