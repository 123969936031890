import MainHeader from "../../components/v2/MainHeader";
import MianFooter from "../../components/v2/MianFooter";
import cricketImage from "./Community-Banner-Cricket.jpg";
import badmintonImage from "./Community-Banner-Badminton.jpg";
import footbalImage from "./Community-Banner-Football.jpg";
import DrunkerGames from "./Drunker-Games.png";

import "./index.css";
import SearchBar from "../../components/SearchForm/SearchBar";
const Search = () => {
  return (
    <div
      style={{
        display: "flex",
        // alignItems: "Center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          background: "#fff",
          maxWidth: 500,
          flex: 1,
        }}
      >
        <MainHeader />
        <div
          style={{
            flex: 1,
            overflow: "scroll",
            overflowX: "hidden",
            paddingBottom: 20,
          }}
        >
          {/* <SearchBar /> */}
          <div
            style={{
              borderTop: "1px SOLID #eee",
              marginTop: 10,
              padding: 20,
            }}
          >
            {/* <div style={{ border: "1px solid #eee" }}>
              <img
                src={DrunkerGames}
                alt="Drunker-Games"
                // width="100%"
                style={{
                  maxHeight: 300,
                  maxWidth: "100%",
                  width: "auto",
                  margin: "auto",
                }}
              />
              <div style={{ display: "flex" }}>
                <idv style={{ padding: 20, fontSize: 12, color: "#444" }}>
                  It’s simple, a game where every wide counts as two runs. And
                  you’ll probably bowl many of them.
                </idv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                  }}
                >
                  <button
                    style={{
                      width: 120,
                      border: "none",
                      background: "#e25556",
                      color: "#fff",
                      padding: 10,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    KNOW MORE
                  </button>
                </div>
              </div>
            </div> */}

            <div style={{ border: "1px solid #eee", marginTop: 10 }}>
              <img
                src={cricketImage}
                alt="Drunker-Games"
                // width="100%"
                style={{
                  maxHeight: 300,
                  maxWidth: "100%",
                  width: "auto",
                  margin: "auto",
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <idv
                  style={{
                    padding: 20,
                    fontSize: 14,
                    color: "#333",
                    textAlign: "justify",
                  }}
                >
                  Cricket: Join the Gully Gang! If you are from Bengaluru and
                  are looking to play cricket once a week in a safe environment
                  and everything taken care of (bats, water, tennis balls,
                  wickets, booking, literally everything), just say Hello Gully
                  Gang - Followed by your phone number and we'll add you to the
                  community.
                </idv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                    paddingTop: 0,
                  }}
                >
                  <a
                    style={{ color: "#222", textDecoration: "none" }}
                    href={"https://chat.whatsapp.com/Eo8Ar2utEzI07PD94qoJNu"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      style={{
                        // width: 120,
                        border: "none",
                        background: "#e25556",
                        color: "#fff",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      JOIN NOW
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div style={{ border: "1px solid #eee", marginTop: 10 }}>
              <img
                src={badmintonImage}
                alt="Drunker-Games"
                // width="100%"
                style={{
                  maxHeight: 300,
                  maxWidth: "100%",
                  width: "auto",
                  margin: "auto",
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <idv
                  style={{
                    padding: 20,
                    fontSize: 14,
                    color: "#333",
                    textAlign: "justify",
                  }}
                >
                  Badminton: Net Ninjas are taking over Bengaluru and they're
                  looking for you to join them. It's simple, we play once a
                  week, Sprentzo gets the best racquets and top shoes, handles
                  it all for you basically. You just need to show up. Just say
                  Net Ninja Takeover - Followed by your phone number and we'll
                  help you become a Net Ninja.
                </idv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                    paddingTop: 0,
                  }}
                >
                  <a
                    style={{ color: "#222", textDecoration: "none" }}
                    href={"https://chat.whatsapp.com/Eo8Ar2utEzI07PD94qoJNu"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      style={{
                        // width: 120,
                        border: "none",
                        background: "#e25556",
                        color: "#fff",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      JOIN NOW
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ border: "1px solid #eee", marginTop: 10 }}>
              <img
                src={footbalImage}
                alt="Drunker-Games"
                // width="100%"
                style={{
                  maxHeight: 300,
                  maxWidth: "100%",
                  width: "auto",
                  margin: "auto",
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <idv
                  style={{
                    padding: 20,
                    fontSize: 14,
                    color: "#333",
                    textAlign: "justify",
                  }}
                >
                  Football: The turfers are here to stay. If you want to have a
                  good game once in a while, meet interesting people and have a
                  kick-about. This is the group for you, oh and we'll also give
                  you live updates on national team games and exclusive
                  discounts. You will also stand a chance to meet and greet a
                  few athletes once in a blue moon. Just say Hello Turfers -
                  Followed by your phone number and we'll add you to the
                  community.
                </idv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                    paddingTop: 0,
                  }}
                >
                  <a
                    style={{ color: "#222", textDecoration: "none" }}
                    href={"https://chat.whatsapp.com/Eo8Ar2utEzI07PD94qoJNu"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      style={{
                        // width: 120,
                        border: "none",
                        background: "#e25556",
                        color: "#fff",
                        padding: 10,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      JOIN NOW
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MianFooter />
      </div>
    </div>
  );
};
export default Search;
