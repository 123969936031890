import React, { useState } from 'react';
import './index.css';

function ProductStockDetails() {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    }

    return (
        <>
        <div className="product-details-stock-title">In stock</div>
        <div className="radio-buttons">
      <label className="radio-buttons-label">
      Rent for ₹120
        <input
          type="radio"
          value="option1"
          checked={selectedOption === 'option1'}
          onChange={handleOptionChange}
          className="radio-button-input"
        />
        <span className="radio-button-custom"></span>
       
      </label>
      <br />
      <label className="radio-buttons-label">
      Buy for ₹800
        <input
          type="radio"
          value="option2"
          checked={selectedOption === 'option2'}
          onChange={handleOptionChange}
          className="radio-button-input"
        />
        <span className="radio-button-custom"></span>
      
      </label>
    </div>
        </>
    );
}

export default ProductStockDetails;
